import { useSnackbar } from 'notistack';

export default function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();
  return (err: Error) => {
    // tslint:disable-next-line: no-console
    console.error(err);
    enqueueSnackbar('Es ist ein Fehler aufgetreten.', { variant: 'error' });
  };
}
