import { Box, Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import { StepAttributes } from './StepperWithControls';

interface Props {
  steps: StepAttributes[];
  activeStep: number;
}

const DesktopStepDisplay = ({ steps, activeStep }: Props) => {
  return (
    <Box pt={2} pb={5} component="div" display={{ xs: 'none', xl: 'block', lg: 'block', md: 'block', sm: 'block' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(entry => (
          <Step key={entry.title}>
            <StepLabel>{''}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default DesktopStepDisplay;
