import { performGet, performPost, performPut } from 'core/apiUtils';

export interface ProfileProgress {
  state: string;
  step: number;
}

export async function getProgress() {
  return await performGet<ProfileProgress>('/progress');
}

export async function updateProgress(progress: ProfileProgress) {
  return performPut<void>('/progress', progress);
}

export async function submitProfile() {
  return performPost<void>('/submit');
}
