import { performGet, performPost } from 'core/apiUtils';
import { PersonalDataStateType } from 'shared/components/resumeGeneratorStepper/BasePersonalDataStep';
import { PositionType } from 'shared/components/resumeGeneratorStepper/Positions';
import { RequestPayloadResumeDataType } from 'shared/components/resumeGeneratorStepper/ResumeGeneratorContextManager';
interface ResumeDataType {
  baseData: PersonalDataStateType;
  positions: PositionType[];
}

export interface ResumeDataPayloadType {
  resumeData: ResumeDataType;
}

export async function generateResumeFromUserData(payload: ResumeDataPayloadType) {
  return await performPost<void>('/resume/generateResume', payload);
}

export async function getBasePersonalData() {
  return await performGet<PersonalDataStateType>('/resume/getBasePersonalData');
}

export async function getResumeData() {
  return await performGet<RequestPayloadResumeDataType>('/resume/getResumeData');
}
