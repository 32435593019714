import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';

import { getResumeData } from 'shared/api/generateResume';
import {
  InitialPersonalDataState,
  PersonalDataStateType
} from 'shared/components/resumeGeneratorStepper/BasePersonalDataStep';
import {
  ComputerSkillType,
  InitialComputerSkillState
} from 'shared/components/resumeGeneratorStepper/ComputerSkillsStep';
import { EducationType, InitialEducationEntryState } from 'shared/components/resumeGeneratorStepper/EducationStep';
import {
  InitialLanguageSkillsState,
  LanguageSkillsType
} from 'shared/components/resumeGeneratorStepper/LanguageSkillsStep';
import {
  InitialMiscellaneousState,
  MiscellaneousType
} from 'shared/components/resumeGeneratorStepper/MiscellaneousStep';
import { InitialPositionState, PositionType } from 'shared/components/resumeGeneratorStepper/Positions';

export interface RequestPayloadResumeDataType {
  baseData: PersonalDataStateType;
  computerSkills: ComputerSkillType[];
  education: EducationType[];
  languageSkills: LanguageSkillsType[];
  miscSkills: MiscellaneousType[];
  positions: PositionType[];
  resumeImage: string | undefined;
}
interface ResumeGeneratorStateContextType {
  activeStep: number;
  resumeImage: string | undefined;
  stepsLength: number;
  personalData: PersonalDataStateType;
  positionInfo: PositionType;
  positionList: PositionType[];
  educationInfo: EducationType;
  educationList: EducationType[];
  languageSkillInfo: LanguageSkillsType;
  languageSkillsList: LanguageSkillsType[];
  computerSkillInfo: ComputerSkillType;
  computerSkillsList: ComputerSkillType[];
  miscellaneousInfo: MiscellaneousType;
  miscellaneousList: MiscellaneousType[];
  isEdit?: boolean;
}

interface ResumeGeneratorDispatchContextType {
  setActiveStep: (newStep: number) => void;
  setResumeImage: (image: string) => void;
  updatePersonalData: (update: ObjectReducerActionType<PersonalDataStateType>) => void;
  updatePositionInfo: (update: ObjectReducerActionType<PositionType>) => void;
  updatePositionList: (update: ObjectListReducerActionType<PositionType>) => void;
  updateEducationInfo: (update: ObjectReducerActionType<EducationType>) => void;
  updateEducationList: (update: ObjectListReducerActionType<EducationType>) => void;
  updateLanguageSkillInfo: (update: ObjectReducerActionType<LanguageSkillsType>) => void;
  updateLanguageSkillsList: (update: ObjectListReducerActionType<LanguageSkillsType>) => void;
  updateComputerSkillInfo: (update: ObjectReducerActionType<ComputerSkillType>) => void;
  updateComputerSkillsList: (update: ObjectListReducerActionType<ComputerSkillType>) => void;
  updateMiscellaneousInfo: (update: ObjectReducerActionType<MiscellaneousType>) => void;
  updateMiscellaneousList: (update: ObjectListReducerActionType<MiscellaneousType>) => void;
}

const ResumeGeneratorStateContext = createContext<ResumeGeneratorStateContextType | undefined>(undefined);
const ResumeGeneratorDispatchContext = createContext<ResumeGeneratorDispatchContextType | undefined>(undefined);

interface ObjectReducerActionType<T> {
  property: string;
  value: string | Date;
  resetValue?: boolean;
  initialState?: T;
}

function createObjectReducer<T>() {
  return (state: T, action: ObjectReducerActionType<T>): T =>
    action.resetValue && action.initialState
      ? Object.assign({} as T, action.initialState)
      : Object.assign({} as T, { ...state, [action.property]: action.value });
}

interface ObjectListReducerActionType<T> {
  entry?: T;
  clearList?: boolean;
  removeEntryAtIndex?: number;
}

function createObjectListReducer<T>() {
  return (state: T[], action: ObjectListReducerActionType<T>): T[] => {
    const removeElementAtIndex = (index: number) => state.slice(0, index).concat(state.slice(index + 1, state.length));
    if (action.clearList) {
      return [];
    } else if (action.removeEntryAtIndex !== undefined) {
      return Array.from([...removeElementAtIndex(action.removeEntryAtIndex)]);
    } else if (action.entry) {
      const checkIfAlreadyInState = state.find(entry => {
        let result = false;
        if (action.entry) {
          result = JSON.stringify(action.entry) === JSON.stringify(entry);
        }
        return result;
      });
      return checkIfAlreadyInState ? state : Array.from([...state, action.entry]);
    } else {
      throw new Error('no valid action specified');
    }
  };
}

interface Props {
  children: React.ReactNode;
  stepCount: number;
  isEdit?: boolean;
}

function useSimpleReducer<T>(initialState: T) {
  return useReducer(createObjectReducer<T>(), initialState);
}

function useListReducer<T>(initialState: T[]) {
  return useReducer(createObjectListReducer<T>(), initialState);
}

export default function ResumeGeneratorContextManager({ children, stepCount, isEdit }: Props) {
  const [activeStep, setActiveStep] = useState(0);
  const [resumeImage, setResumeImage] = useState();
  const [personalData, updatePersonalData] = useSimpleReducer<PersonalDataStateType>(InitialPersonalDataState);
  const [positionInfo, updatePositionInfo] = useSimpleReducer<PositionType>(InitialPositionState);
  const [positionList, updatePositionList] = useListReducer<PositionType>([]);
  const [educationInfo, updateEducationInfo] = useSimpleReducer<EducationType>(InitialEducationEntryState);
  const [educationList, updateEducationList] = useListReducer<EducationType>([]);
  const [languageSkillInfo, updateLanguageSkillInfo] = useSimpleReducer<LanguageSkillsType>(InitialLanguageSkillsState);
  const [languageSkillsList, updateLanguageSkillsList] = useListReducer<LanguageSkillsType>([]);
  const [computerSkillInfo, updateComputerSkillInfo] = useSimpleReducer<ComputerSkillType>(InitialComputerSkillState);
  const [computerSkillsList, updateComputerSkillsList] = useListReducer<ComputerSkillType>([]);
  const [miscellaneousInfo, updateMiscellaneousInfo] = useSimpleReducer<MiscellaneousType>(InitialMiscellaneousState);
  const [miscellaneousList, updateMiscellaneousList] = useListReducer<MiscellaneousType>([]);
  const [loadingResumeData, setLoadingResumeData] = useState(false);

  async function getSavedResumeData() {
    setLoadingResumeData(true);
    const initStateObj = (value: any, setFn: any) => {
      Object.keys(value).forEach(property => {
        if (property === 'dateOfBirth') {
          setFn({
            property,
            value: new Date(value.dateOfBirth)
          });
        } else {
          setFn({
            property,
            value: value[property]
          });
        }
      });
    };
    const initStateObjList = (value: any, updateListFn: any, hasDate?: boolean) => {
      value.forEach((element: any) => {
        if (hasDate) {
          const datePropNameList = Object.keys(element).filter(key => !isNaN(Date.parse(element[key])));
          const newElement = Object.assign({}, { ...element });
          if (datePropNameList.length > 0) {
            datePropNameList.forEach(name => {
              newElement[name] = new Date(element[name]);
            });
          }
          updateListFn({ entry: newElement });
        } else {
          updateListFn({ entry: element });
        }
      });
    };

    const {
      baseData,
      computerSkills,
      education,
      languageSkills,
      miscSkills,
      positions,
      resumeImage
    }: RequestPayloadResumeDataType = await getResumeData();
    initStateObj(baseData, updatePersonalData);
    initStateObjList(positions, updatePositionList, true);
    initStateObjList(education, updateEducationList, true);
    initStateObjList(languageSkills, updateLanguageSkillsList);
    initStateObjList(computerSkills, updateComputerSkillsList);
    initStateObjList(miscSkills, updateMiscellaneousList);
    setResumeImage(resumeImage);
    setLoadingResumeData(false);
  }

  useEffect(() => {
    if (isEdit) {
      getSavedResumeData();
    }
  }, [isEdit]);

  const state: ResumeGeneratorStateContextType = {
    activeStep,
    resumeImage,
    stepsLength: stepCount,
    positionList,
    positionInfo,
    educationList,
    educationInfo,
    languageSkillsList,
    languageSkillInfo,
    computerSkillInfo,
    computerSkillsList,
    miscellaneousInfo,
    miscellaneousList,
    personalData,
    isEdit: !!isEdit
  };

  const dispatchers: ResumeGeneratorDispatchContextType = {
    setActiveStep,
    setResumeImage,
    updatePersonalData,
    updatePositionInfo,
    updatePositionList,
    updateEducationInfo,
    updateEducationList,
    updateLanguageSkillInfo,
    updateLanguageSkillsList,
    updateComputerSkillInfo,
    updateComputerSkillsList,
    updateMiscellaneousInfo,
    updateMiscellaneousList
  };

  return (
    <>
      {loadingResumeData ? (
        <Box mt={5}>
          <Typography align="center" component="div">
            <CircularProgress size={150} thickness={2.1} />
            <Typography align="center" component="h5" variant="h5">
              Lebenslauf Daten werden geladen
            </Typography>
          </Typography>
        </Box>
      ) : (
        <ResumeGeneratorStateContext.Provider value={state}>
          <ResumeGeneratorDispatchContext.Provider value={dispatchers}>
            {children}
          </ResumeGeneratorDispatchContext.Provider>
        </ResumeGeneratorStateContext.Provider>
      )}
    </>
  );
}

function useResumeGeneratorState() {
  const context = useContext(ResumeGeneratorStateContext);
  if (context === undefined) {
    throw new Error('useResumeGeneratorState must be used within a ResumeGeneratorStateContext');
  }
  return context;
}

function useResumeGeneratorDispatchers() {
  const context = useContext(ResumeGeneratorDispatchContext);
  if (context === undefined) {
    throw new Error('useResumeGeneratorDispatchers must be used within a ResumeGeneratorDispatchContext');
  }
  return context;
}

export { useResumeGeneratorState, useResumeGeneratorDispatchers };
