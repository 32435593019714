import { Box, Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';

import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';

function Controls({ nextStepDisabled }: { nextStepDisabled: boolean }) {
  const state = useResumeGeneratorState();
  const dispatchers = useResumeGeneratorDispatchers();
  const nextStep = () => dispatchers.setActiveStep(state.activeStep + 1);
  const previousStep = () => dispatchers.setActiveStep(state.activeStep - 1);
  const isEndStep = state.activeStep === state.stepsLength;
  return (
    <>
      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={12} style={{ textAlign: 'right' }}>
        {state.activeStep !== 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={previousStep}
            style={isEndStep ? {} : { marginRight: '1em' }}
          >
            Zurück
          </Button>
        )}
        {!isEndStep && (
          <Button variant="contained" color="primary" onClick={nextStep} disabled={nextStepDisabled}>
            Weiter
          </Button>
        )}
      </Grid>
    </>
  );
}

interface Props {
  title: string;
  children: React.ReactNode;
  extraStepperControls?: React.ReactNodeArray;
  nextStepDisabled?: boolean;
}

export default function ResumeStepperContent({ title, children, extraStepperControls, nextStepDisabled }: Props) {
  return (
    <Paper>
      <Box p={4} pt={3}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography variant="h6" component="h6" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item md={4} style={{ textAlign: 'right' }}>
            {extraStepperControls}
          </Grid>
          {children}
          <Controls nextStepDisabled={!!nextStepDisabled} />
        </Grid>
      </Box>
    </Paper>
  );
}
