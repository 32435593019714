import { Grid } from '@material-ui/core';
import DropDownSelectField from 'core/components/generic_components/form/DropDownSelectField';
import * as Keycloack from 'core/keycloak/keycloak';
import { useNestedStateFn } from 'core/utils/useNestedState';
import React, { useMemo } from 'react';
import FormInputField from '../../../../core/components/generic_components/form/FormInputField';
import COUNTRY_NAMES from '../../../static_data/countryNames';
import SALUTATION from '../../../static_data/salutation';

interface Props {
  readOnly?: boolean;
  value: ClientPersonalData;
  onChange: React.Dispatch<React.SetStateAction<ClientPersonalData>>;
  enableJobPosition?: boolean;
}

export interface ClientPersonalData {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  title?: string;
  country?: string;
  city?: string;
  postalCode?: string;
  streetName?: string;
  streetNumber?: string;
  telephoneNumber?: string;
  mobileNumber?: string;
  clientWebsiteURL?: string;
  xingUrl?: string;
  linkedinUrl?: string;
  currentJobPosition?: string;
}

const readOnlyProps = {
  readOnly: true,
  disableUnderline: true,
  notched: true
};

export const PersonalDataFormFields = ({ readOnly, value, onChange, enableJobPosition }: Props) => {
  const keycloakedTokenParsed: any = Keycloack.keycloak.tokenParsed;
  const emailAddress: string | undefined = keycloakedTokenParsed.email;

  return (
    <Grid container spacing={3}>
      <DropDownSelectField
        readOnly={readOnly}
        fieldName="Anrede"
        optionList={SALUTATION}
        value={value.salutation}
        onChange={useNestedStateFn(onChange, 'salutation')}
      />

      <FormInputField
        label="Titel"
        value={value.title}
        onChange={useNestedStateFn(onChange, 'title')}
        InputProps={readOnly ? readOnlyProps : undefined}
      />

      <FormInputField
        label="Vorname"
        value={value.firstName}
        onChange={useNestedStateFn(onChange, 'firstName')}
        InputProps={readOnly ? readOnlyProps : undefined}
      />
      <FormInputField
        label="Name"
        value={value.lastName}
        onChange={useNestedStateFn(onChange, 'lastName')}
        InputProps={readOnly ? readOnlyProps : undefined}
      />
      <FormInputField label="Email" value={emailAddress || ''} InputProps={readOnlyProps} />

      <DropDownSelectField
        readOnly={readOnly}
        fieldName="Land"
        optionList={COUNTRY_NAMES}
        value={value.country}
        onChange={useNestedStateFn(onChange, 'country')}
      />
      <FormInputField
        label="PLZ"
        value={value.postalCode}
        required
        onChange={useNestedStateFn(onChange, 'postalCode')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'postal-code',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />
      <FormInputField
        label="Ort"
        value={value.city}
        required
        onChange={useNestedStateFn(onChange, 'city')}
        InputProps={readOnly ? readOnlyProps : undefined}
      />

      <FormInputField
        label="Straße"
        value={value.streetName}
        required
        onChange={useNestedStateFn(onChange, 'streetName')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'address-line1',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />

      <FormInputField
        label="Hausnummer"
        value={value.streetNumber}
        required
        onChange={useNestedStateFn(onChange, 'streetNumber')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'address-line2',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />

      <FormInputField
        label="Mobilnummer"
        value={value.mobileNumber}
        inputType="tel"
        required
        onChange={useNestedStateFn(onChange, 'mobileNumber')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'tel',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />

      <FormInputField
        label="Telefonnummer"
        value={value.telephoneNumber}
        inputType="tel"
        onChange={useNestedStateFn(onChange, 'telephoneNumber')}
        InputProps={readOnly ? readOnlyProps : undefined}
      />

      {enableJobPosition && (
        <FormInputField
          label="Aktuelle Position"
          value={value.currentJobPosition}
          required={true}
          inputType="text"
          onChange={useNestedStateFn(onChange, 'currentJobPosition')}
          InputProps={useMemo(
            () => ({
              autoComplete: 'current-job-position',
              ...(readOnly && readOnlyProps)
            }),
            [readOnly]
          )}
        />
      )}

      <FormInputField
        label="Webseite"
        value={value.clientWebsiteURL}
        inputType="text"
        onChange={useNestedStateFn(onChange, 'clientWebsiteURL')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'url',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />

      <FormInputField
        label="Xing Profil"
        value={value.xingUrl}
        inputType="text"
        onChange={useNestedStateFn(onChange, 'xingUrl')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'url',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />

      <FormInputField
        label="LinkedIn Profil"
        value={value.linkedinUrl}
        inputType="text"
        onChange={useNestedStateFn(onChange, 'linkedinUrl')}
        InputProps={useMemo(
          () => ({
            autoComplete: 'url',
            ...(readOnly && readOnlyProps)
          }),
          [readOnly]
        )}
      />
    </Grid>
  );
};
