import { Dispatch, SetStateAction, useCallback } from 'react';

/**
 * Pass in the return values of useState, returns the same format mapped to a
 * property of the original state object
 */
export function useNestedState<F extends string, S extends Record<F, any>>(
  state: S,
  setState: Dispatch<SetStateAction<S>>,
  fieldName: F
): [S[F], Dispatch<SetStateAction<S[F]>>] {
  return [state[fieldName], useNestedStateFn(setState, fieldName)];
}

/**
 * returns a memoized function that updates the given field using the provided setState function
 * of the parent state
 */
export function useNestedStateFn<F extends string, S extends Record<F, any>>(
  setState: Dispatch<SetStateAction<S>>,
  fieldName: F
): Dispatch<SetStateAction<S[F]>> {
  const setNestedState = useCallback(
    (nestedState: React.SetStateAction<S[F]>) =>
      setState(state => ({
        ...state,
        [fieldName]: typeof nestedState === 'function' ? (nestedState as any)(state[fieldName]) : nestedState
      })),
    [setState]
  );
  return setNestedState;
}
