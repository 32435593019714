const theme = {
  name: 'Personal-Total',
  login: {
    typography: {
      useNextVariants: true
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#962225',
        light: '#fff',
        contrastText: '#fff'
      }
    }
  },
  app: {
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: '#962225',
        light: '#b46365',
        veryLight: '#e89698',
        contrastText: '#fff'
      },
      secondary: { main: '#962225' }
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: '#fff'
        }
      },
      MuiButton: {
        label: {
          color: 'white'
        }
      },
      MuiStepIcon: {
        text: {
          fill: 'white',
          color: 'white'
        }
      },
      MuiTooltip: {
        tooltip: {
          'font-size': '14px',
          'max-width': '500px'
        }
      }
    },
    status: {
      danger: 'orange'
    }
  },
  loginBackground: {
    background: '#5f6467'
  },
  loginPaper: {
    background: '#fff'
  },
  appBar: {
    background: '#671314',
    zIndex: '1100',

    candidates: {
      background: 'rgba(88,125,175,0.4)'
    },
    companies: {
      background: 'rgba(136,179,175,0.4)'
    },
    projects: {
      background: 'rgba(197,197,184,0.4)'
    }
  }
};

module.exports = theme;
