import { Grid, Paper } from '@material-ui/core';
import LoadingOverlay from 'core/components/LoadingOverlay';
import useErrorHandler from 'core/utils/useErrorHandler';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getProgress, ProfileProgress } from '../api/progress';
import MenuAppBar from '../components/MenuAppBar';
import RegistrationStepper, { RegistrationStep } from '../components/RegistrationStepper';

interface Props extends RouteComponentProps {
  steps: Array<RegistrationStep<any, any>>;
  logoImage: string;
  brand: string;
}

const RegistrationPage = ({ steps, logoImage, history, brand }: Props) => {
  const [progress, setProgress] = useState<ProfileProgress>();
  const handleError = useErrorHandler();
  useEffect(() => {
    getProgress()
      .then(progress => {
        setProgress(progress);
        const jobNumberFromUrl = new URLSearchParams(window.location.search).get('jobNumber')

        if (progress.state !== 'submitted') {
          history.replace(`/register?jobNumber=${jobNumberFromUrl}`);
        } else if (history.location.pathname === '/register') {
          history.replace('/');
        }
      })
      .catch(handleError);
  }, []);
  return progress ? (
    <React.Fragment>
      <Grid item xs />
      <Grid item xs={12} sm={8} md={5}>
        <Paper elevation={6} style={{ minHeight: '100%' }}>
          <MenuAppBar brand={brand} />

          <div style={{ textAlign: 'center', paddingTop: '1em', paddingBottom: '1em' }}>
            <img alt="logo" src={logoImage} style={{ margin: 'auto', maxWidth: '100%', padding: '10px' }} />
          </div>
          <RegistrationStepper steps={steps} progressStep={progress.step} brand={brand} />
        </Paper>
      </Grid>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <LoadingOverlay />
    </React.Fragment>
  );
};

export default RegistrationPage;
