import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Tooltip } from '@material-ui/core';
import { ClearAll as ClearAllIcon, DeleteOutline as DeleteOutlineIcon, Save as SaveIcon } from '@material-ui/icons';
import React from 'react';

import { DataFieldType } from './inputFieldList';
import ResumeInputField from './ResumeInputField';
import ResumeStepperContent from './ResumeStepperContent';

interface EntryProps {
  entryData: object;
  dataFieldList: DataFieldType[];
  getColSize?: (filedType: string) => any;
  handleOnChange?: (property: string, value: string | Date) => void;
  readOnly?: boolean;
}

function Entry({ entryData, handleOnChange, readOnly, dataFieldList, getColSize }: EntryProps) {
  return (
    <>
      {dataFieldList.map((dataField: DataFieldType) => (
        <Grid
          key={`${dataField.fieldId}_${dataField.fieldType}_entry`}
          item
          md={getColSize ? getColSize(dataField.fieldType) : 6}
        >
          <ResumeInputField
            property={dataField.fieldId}
            label={dataField.label}
            value={entryData[dataField.fieldId]}
            type={dataField.fieldType}
            onChange={handleOnChange ? handleOnChange : () => undefined}
            readOnly={readOnly}
            dropDownOptions={dataField.listOfSelections ? dataField.listOfSelections : []}
            helperText={dataField.helperText ? dataField.helperText : ''}
          />
        </Grid>
      ))}
    </>
  );
}

interface Props {
  title: string;
  dataFieldList: DataFieldType[];
  listOfEntries: object[];
  newEntryState: object;
  newEntryStateUpdate: (property: string, value: string | Date) => void;
  addNewEntry: (entry: object) => void;
  removeEntryFromList: (index: number) => void;
  removeAllEntries: () => void;
  getColSize?: (fieldType: string) => void;
}

export default function MultipleEntryList({
  title,
  dataFieldList,
  listOfEntries,
  newEntryState,
  newEntryStateUpdate,
  addNewEntry,
  removeEntryFromList,
  removeAllEntries,
  getColSize
}: Props) {
  function noEntryFieldEmpty() {
    let result = true;
    Object.keys(newEntryState).forEach(entry => {
      if (entry === 'untilDate' || entry === 'educationUntilDate') {
        result = result && true;
      } else {
        result = result && (newEntryState[entry] ? true : false);
      }

      if (newEntryState[entry] instanceof Date) {
        result = result && !isNaN(newEntryState[entry]);
      }
    });
    return result;
  }

  function createNewEntry() {
    return (
      <>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Box p={1} mb={4} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Entry
              entryData={newEntryState}
              dataFieldList={dataFieldList}
              handleOnChange={newEntryStateUpdate}
              getColSize={getColSize}
            />
            <Grid item md={12} style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  addNewEntry(newEntryState);
                }}
                disabled={!noEntryFieldEmpty()}
              >
                <SaveIcon style={{ marginRight: '10px' }} />
                Eintrag Speichern
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  function renderList() {
    return (
      <>
        {listOfEntries.map((entry: object, index: number) => (
          <Box pb={1} key={`${JSON.stringify(entry).replace(/[^0-9A-Za-z]+/g, '')}_card`} style={{ width: '100%' }}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} style={{ textAlign: 'right' }}>
                    <Tooltip title="Eintrag löschen" placement="top">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          removeEntryFromList(index);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item md={12}>
                    <Divider />
                  </Grid>
                  <Entry entryData={entry} dataFieldList={dataFieldList} readOnly getColSize={getColSize} />
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </>
    );
  }

  function renderClearAllButton() {
    const disabled = listOfEntries.length ? false : true;
    const clearAllButton = (
      <IconButton color="primary" onClick={removeAllEntries} disabled={disabled}>
        <ClearAllIcon />
      </IconButton>
    );
    const buttonWithTooltip = (
      <Tooltip key={`deleteAll_${title}`} title="Alle Einträge entfernen" placement="right">
        {clearAllButton}
      </Tooltip>
    );
    return disabled ? clearAllButton : buttonWithTooltip;
  }

  return (
    <ResumeStepperContent
      title={title}
      extraStepperControls={[renderClearAllButton()]}
      nextStepDisabled={listOfEntries.length === 0}
    >
      {renderList()}
      {createNewEntry()}
    </ResumeStepperContent>
  );
}
