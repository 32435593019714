import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  AssignmentInd as AssignmentIndIcon,
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Folder as FolderIcon,
  Lock as LockIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import ConfirmationDialogWrapper from './DialogWrapper';
import PdfViewerModal from './PdfViewerModal';

interface FileLineProps {
  file: UploadedFile;
  onClick: (file: UploadedFile) => void;
  onDownload: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  loading?: boolean;
}

interface UploadedFile {
  id: string;
  documentName: string;
  documentType?: string;
}

function FileIcon({ type }: { type?: string }) {
  if (type === 'CV') {
    return <AssignmentIndIcon color="primary" />;
  }
  return <FolderIcon color="primary" />;
}

const FileLine = ({ file, onClick, onDownload, onDelete, onEdit, loading }: FileLineProps) => {
  const iconButtonStyle = { marginLeft: '10px' };
  return (
    <ListItem disabled={loading}>
      <ListItemIcon>{loading ? <LockIcon /> : <FileIcon type={file.documentType} />}</ListItemIcon>
      <ListItemText primary={loading ? 'Dokument wird gerade gelöscht!' : file.documentName} />
      <ListItemSecondaryAction>
        <Tooltip title="Dokument anzeigen" placement="top">
          <IconButton
            onClick={() => onClick(file)}
            size="small"
            color="secondary"
            style={iconButtonStyle}
            disabled={loading}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Dokument herunterladen" placement="top">
          <IconButton onClick={onDownload} size="small" color="secondary" style={iconButtonStyle} disabled={loading}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
        {onEdit && (
          <Tooltip title="Dokument bearbeiten" placement="top">
            <IconButton onClick={onEdit} size="small" color="secondary" style={iconButtonStyle} disabled={loading}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title="Dokument löschen" placement="top">
            <IconButton onClick={onDelete} size="small" color="secondary" style={iconButtonStyle} disabled={loading}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface Props {
  files: UploadedFile[];
  emptyFileListText: string;
  onDelete?: (setDeleteActionLoading: (value: boolean) => void, fileId: string) => Promise<void>;
  onEdit?: () => void;
  onDownload: (id: string, name: string) => void;
  getFileUrl?: (id: string, name: string) => Promise<string | number | null | undefined>;
}

export const FileListingContainer = ({ files, emptyFileListText, onDelete, onDownload, getFileUrl, onEdit }: Props) => {
  const [pdfFile, setPdfFile] = useState<UploadedFile>();
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fileIdTobeDeleted, setFileIdTobeDeleted] = useState();
  const [toBeDeletedFileName, setToBeDeletedFileName] = useState();
  const [deleteActionLoading, setDeleteActionLoading] = useState();

  function view(file: UploadedFile) {
    setPdfFile(file);
    setPdfViewerOpen(true);
  }

  return (
    <>
      <List>
        {files.map(file => (
          <FileLine
            key={file.id}
            file={file}
            onClick={view}
            onDownload={() => onDownload(file.id, file.documentName)}
            onDelete={
              onDelete &&
              (() => {
                setToBeDeletedFileName(file.documentName);
                setFileIdTobeDeleted(file.id);
                setIsDeleteDialogOpen(true);
              })
            }
            loading={deleteActionLoading}
            onEdit={onEdit}
          />
        ))}
        {files.length === 0 && (
          <Typography variant="h5" align="center">
            {emptyFileListText}
          </Typography>
        )}
      </List>
      {pdfFile && (
        <PdfViewerModal
          file={pdfFile}
          open={pdfViewerOpen}
          onClose={() => setPdfViewerOpen(false)}
          onDownload={onDownload}
          getFileUrl={getFileUrl}
        />
      )}
      {fileIdTobeDeleted && setToBeDeletedFileName && (
        <ConfirmationDialogWrapper
          open={isDeleteDialogOpen}
          handleClose={() => setIsDeleteDialogOpen(false)}
          handleSubmit={
            onDelete
              ? () => {
                  new Promise(_ => {
                    onDelete(setDeleteActionLoading, fileIdTobeDeleted);
                    setIsDeleteDialogOpen(false);
                  }).then();
                }
              : () => undefined
          }
          dialogContentText={<>Wollen Sie das Dokument {toBeDeletedFileName} löschen ?</>}
          disabled={deleteActionLoading}
          dialogTitle="Dokument löschen"
        />
      )}
    </>
  );
};

export default FileListingContainer;
