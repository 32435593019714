import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { AssignmentInd as AssignmentIndIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import React from 'react';

import { generateResumeFromUserData } from 'shared/api/generateResume';
import { useResumeGeneratorState } from './ResumeGeneratorContextManager';
import ResumeStepperContent from './ResumeStepperContent';

interface Props {
  endAction?: () => void;
}

export default function EndStep({ endAction }: Props) {
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const state = useResumeGeneratorState();
  const leftMarginStyle = { marginRight: '10px' };

  async function generateResumeDocumentApiCall() {
    try {
      const payload = {
        resumeData: {
          baseData: state.personalData,
          positions: state.positionList,
          resumeImage: state.resumeImage,
          education: state.educationList,
          languageSkills: state.languageSkillsList,
          computerSkills: state.computerSkillsList,
          miscSkills: state.miscellaneousList,
          partner:process.env.REACT_APP_MODULE
        },
        isResumeEdit: state.isEdit
      };
      setLoading(true);
      await generateResumeFromUserData(payload);
      setLoading(false);

      if (endAction) {
        endAction();
        enqueueSnackbar('Lebenslauf wurde erfolgreich generiert', { variant: 'success' });
      }
    } catch (e) {
      setLoading(false);
      if (endAction) {
        endAction();
        enqueueSnackbar(`Ein Fehler ist Aufgetreten: ${e}`, { variant: 'error' });
      }
    }
  }

  return (
    <ResumeStepperContent title="Lebenslauf generieren">
      <Grid item md={12}>
        <Box p={5} style={{ textAlign: 'center' }}>
          <Button disabled={loading} variant="contained" color="primary" onClick={generateResumeDocumentApiCall}>
            {loading ? (
              <CircularProgress size={18} style={leftMarginStyle} />
            ) : (
              <AssignmentIndIcon style={leftMarginStyle} />
            )}
            Lebenslauf generieren
          </Button>
        </Box>
      </Grid>
    </ResumeStepperContent>
  );
}
