export function dataURIToBlob(dataURI: string) {
  const binStr = atob(dataURI.split(',')[1]);

  const len = binStr.length;

  const arr = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr]);
}

export function downloadFile(fileName: string, fileUrl: string) {
  const element = document.createElement('a');
  if (fileUrl.startsWith('data:')) {
    const blob = dataURIToBlob(fileUrl);
    element.setAttribute('href', URL.createObjectURL(blob));
  } else {
    element.setAttribute('href', fileUrl);
  }
  // element.setAttribute('target', '_blank');

  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
