import { AppBar, Box, CircularProgress, Dialog, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close as CloseIcon, CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { createStyles, withStyles } from '@material-ui/styles';

// https://github.com/wojtekmaj/react-pdf/wiki/Known-issues#create-react-app-1
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = createStyles({
  modalStyle1: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    textAlign: 'center',
    overflow: 'scroll',
    height: '100%',
    display: 'block'
  },
  pdfPaper: {
    backgroundColor: 'transparent'
  },
  appBarDrawer: {
    backgroundColor: '#222'
  },
  appBarTitle: {
    marginLeft: '1em',
    flex: 1
  },
  pdfPageStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em'
  },
  customMxAuto: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important'
  }
});

interface File {
  id: string;
  documentName: string;
  documentType?: string;
}

interface Props {
  file: File;
  open: boolean;
  onClose: () => void;
  onDownload: (fileId: string, fileName: string) => void;
  getFileUrl?: (fileId: string, fileName: string) => Promise<string | number | null | undefined>;
  classes: {
    modalStyle1: string;
    pdfPaper: string;
    appBarDrawer: string;
    appBarTitle: string;
    customMxAuto: string;
    pdfPageStyle: string;
  };
}

function PdfViewerModal({ file, open, onClose, classes, onDownload, getFileUrl }: Props) {
  const [numPages, setNumPages] = useState(0);
  const [fileUrl, setFileUrl] = useState();

  async function getDocumentUrl() {
    if (getFileUrl && file && file.id && file.documentName) {
      const apiResponse = await getFileUrl(file.id, file.documentName);
      setFileUrl(apiResponse);
    }
  }

  useEffect(() => {
    getDocumentUrl();
  }, [file]);

  function displayPdfDocumentPages(numPages: number) {
    const generatePage = (pageNumber: number) => (
      <Page
        key={`page_${pageNumber}_pdf_${file.documentName}`}
        className={[classes.customMxAuto, classes.pdfPageStyle]}
        pageNumber={pageNumber + 1}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    );

    return Array.from(Array(numPages).keys(), generatePage);
  }

  function renderAppBar() {
    return (
      <>
        <AppBar className={classes.appBarDrawer} position="sticky">
          <Toolbar>
            <Typography variant="h6" component="h6" className={classes.appBarTitle}>
              {file.documentName}
            </Typography>
            <IconButton color="inherit" onClick={() => onDownload(file.id, file.documentName)} aria-label="download">
              <CloudDownloadIcon />
            </IconButton>
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </>
    );
  }

  const documentNotLoadedError = (
    <Grid container justify="center">
      <Box p={6}>
        <Typography variant="h4" component="div" style={{ color: 'white' }}>
          Dokument wurde nicht geladen
        </Typography>
      </Box>
    </Grid>
  );

  const centeredCircularProgress = (
    <Grid container justify="center">
      <Box p={6}>
        <CircularProgress size={130} />
      </Box>
    </Grid>
  );

  return (
    <Dialog fullScreen open={open} onClose={onClose} PaperProps={{ className: classes.pdfPaper }}>
      {renderAppBar()}

      <Box mt={3}>
        <Document
          file={fileUrl}
          error={documentNotLoadedError}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
          }}
          loading={centeredCircularProgress}
          className={classes.customMxAuto}
        >
          {displayPdfDocumentPages(numPages)}
        </Document>
      </Box>
    </Dialog>
  );
}

export default withStyles(styles)(PdfViewerModal);
