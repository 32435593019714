import i18n from 'core/utils/i18n';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// tslint:disable-next-line: no-var-requires
const App: React.ComponentType = require(`./${process.env.REACT_APP_MODULE}/App`).default;

i18n.changeLanguage('de');
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
