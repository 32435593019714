import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { UploadedFile } from '../../api/document';
import { StepComponentProps } from '../RegistrationStepper';
import GenericDocumentManagement from './GenericDocumentManagement';
import ResumeUpload from './ResumeUpload';

const DocumentsUpload = ({
  state,
  setState,
  readOnly,
  validationResult,
  enableResumeDropZone,
  children,
  isProfileSection
}: StepComponentProps<UploadedFile[], false>) => {
  return (
    <React.Fragment>
      {enableResumeDropZone && (
        <React.Fragment>
          {!isProfileSection && children}
          <Box pb={2}>
            <Typography variant="h6" color="textSecondary">
              Lebenslauf
            </Typography>
          </Box>
          <ResumeUpload state={state} setState={setState} readOnly={readOnly} validationResult={validationResult} />
          <Box pb={2}>
            <Typography variant="h6" color="textSecondary">
              Zusätzliche Dokumente
            </Typography>
          </Box>
        </React.Fragment>
      )}

      <GenericDocumentManagement
        state={state}
        setState={setState}
        readOnly={readOnly}
        validationResult={enableResumeDropZone ? undefined : validationResult}
      />
    </React.Fragment>
  );
};

export default DocumentsUpload;
