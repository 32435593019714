import { CircularProgress, Grid } from '@material-ui/core';
import * as React from 'react';

const LoadingOverlay = ({}) => (
  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
    <Grid item style={{ textAlign: 'center' }}>
      <CircularProgress size={100} />
      <div>Loading...</div>
    </Grid>
  </Grid>
);

export default LoadingOverlay;
