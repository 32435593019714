import React from 'react';

import { positionDataFieldList } from './inputFieldList';
import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';

import MultipleEntryList from './MultipleEntryList';

export interface PositionType {
  position?: string;
  companyName?: string;
  fromDate?: Date;
  untilDate?: Date;
  positionDescription?: string;
}

export const InitialPositionState = {
  position: '',
  companyName: '',
  fromDate: undefined,
  untilDate: undefined,
  positionDescription: ''
};

export default function Positions() {
  const state = useResumeGeneratorState();
  const dispatchers = useResumeGeneratorDispatchers();

  function newEntryStateUpdate(property: string, value: string | Date) {
    dispatchers.updatePositionInfo({ property, value });
  }

  function addNewEntry(entry: PositionType) {
    dispatchers.updatePositionList({ entry });
    dispatchers.updatePositionInfo({ property: '', value: '', resetValue: true, initialState: InitialPositionState });
  }

  function removeEntryFromList(index: number) {
    dispatchers.updatePositionList({ removeEntryAtIndex: index });
  }

  function removeAllEntries() {
    dispatchers.updatePositionList({ clearList: true });
  }

  const getColSize = (type: string) => {
    let result = 6;
    if (type === 'description') {
      result = 12;
    }
    return result;
  };

  return (
    <MultipleEntryList
      title="Positionen"
      dataFieldList={positionDataFieldList}
      listOfEntries={state.positionList}
      newEntryState={state.positionInfo}
      newEntryStateUpdate={newEntryStateUpdate}
      addNewEntry={addNewEntry}
      removeEntryFromList={removeEntryFromList}
      removeAllEntries={removeAllEntries}
      getColSize={getColSize}
    />
  );
}
