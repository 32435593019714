import { keycloak } from 'core/keycloak/keycloak';
import { getDetails, ProfilePersonalData, updateDetails } from '../../../api/profile';
import { RegistrationStep } from '../../RegistrationStepper';
import PersonalDataForm from './PersonalDataForm';
import { ClientPersonalData } from './PersonalDataFormFields';

const keyDataStep: RegistrationStep<ClientPersonalData> = {
  title: 'Stammdaten',
  component: PersonalDataForm,
  initialState: {},
  onLoad: () => getDetails().then(mapFormValuesFromDetails),
  onSave: (state: ClientPersonalData) => updateDetails(mapDetailsFromFormValues(state))
};

export function mapFormValuesFromDetails(details: ProfilePersonalData): ClientPersonalData {
  const keycloakedTokenParsed: any = keycloak.tokenParsed;
  const firstName: string | undefined = keycloakedTokenParsed.given_name;
  const lastName: string | undefined = keycloakedTokenParsed.family_name;

  if (details) {
    return {
      firstName: details.firstName || firstName,
      lastName: details.lastName || lastName,
      salutation: details.salutation,
      title: details.title,
      country: details.address && details.address.country,
      city: details.address && details.address.city,
      postalCode: details.address && details.address.postalCode,
      streetName: details.address && details.address.street,
      streetNumber: details.address && details.address.streetNumber,
      telephoneNumber: details.phone,
      mobileNumber: details.mobile,
      currentJobPosition: details.currentJobPosition,
      clientWebsiteURL: details.website,
      xingUrl: details.xingUrl,
      linkedinUrl: details.linkedinUrl
    };
  } else {
    return { country: 'Deutschland', firstName, lastName };
  }
}

export function mapDetailsFromFormValues(formValues: ClientPersonalData, projectNumber?: string): ProfilePersonalData {
  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    salutation: formValues.salutation,
    title: formValues.title,
    address: {
      country: formValues.country,
      city: formValues.city,
      postalCode: formValues.postalCode,
      street: formValues.streetName,
      streetNumber: formValues.streetNumber
    },
    phone: formValues.telephoneNumber,
    mobile: formValues.mobileNumber,
    projectNumber,
    website: formValues.clientWebsiteURL,
    xingUrl: formValues.xingUrl,
    linkedinUrl: formValues.linkedinUrl,
    currentJobPosition: formValues.currentJobPosition
  };
}

export default keyDataStep;
