import React from 'react';

import FullscreenDialog from 'core/components/generic_components/FullscreenDialog';
import ActiveStepContent from 'shared/components/resumeGeneratorStepper/ActiveStepContent';
import BasePersonalDataStep from 'shared/components/resumeGeneratorStepper/BasePersonalDataStep';
import ComputerSkillsStep from 'shared/components/resumeGeneratorStepper/ComputerSkillsStep';
import EducationStep from 'shared/components/resumeGeneratorStepper/EducationStep';
import EndStep from 'shared/components/resumeGeneratorStepper/EndStep';
import LanguageSkillsStep from 'shared/components/resumeGeneratorStepper/LanguageSkillsStep';
import MiscellaneousStep from 'shared/components/resumeGeneratorStepper/MiscellaneousStep';
import Positions from 'shared/components/resumeGeneratorStepper/Positions';
import ResumeGeneratorContextManager from 'shared/components/resumeGeneratorStepper/ResumeGeneratorContextManager';
import ResumeGeneratorStepper from 'shared/components/resumeGeneratorStepper/ResumeGeneratorStepper';
interface Props {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
}

const steps = [
  'Stammdaten',
  'Positionen',
  'Ausbildungen',
  'Sprachen',
  'EDV Kenntnisse',
  'Sonstiges',
  'Lebenslauf generieren'
];

export default function ResumeGenerator({ open, onClose, isEdit }: Props) {
  const stepContent = [
    <BasePersonalDataStep key="stepContent_CandidatePersonalData" />,
    <Positions key="stepContent_Positions" />,
    <EducationStep key="stepContent_EducationStep" />,
    <LanguageSkillsStep key="stepContent_LanguageSkillsStep" />,
    <ComputerSkillsStep key="stepContent_ComputerSkillsStep" />,
    <MiscellaneousStep key="stepContent_MiscellaneousStep" />,
    <EndStep key="stepContent_EndStepContent" endAction={onClose} />
  ];

  return (
    <FullscreenDialog open={open} onClose={onClose}>
      <ResumeGeneratorContextManager stepCount={steps.length - 1} isEdit={isEdit}>
        <ResumeGeneratorStepper stepNameList={steps}>
          <ActiveStepContent stepContentList={stepContent} />
        </ResumeGeneratorStepper>
      </ResumeGeneratorContextManager>
    </FullscreenDialog>
  );
}
