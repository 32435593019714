import React from 'react';

import { computerSkillsDataFieldList } from './inputFieldList';
import MultipleEntryList from './MultipleEntryList';
import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';

export interface ComputerSkillType {
  computerSkillDescription: string;
}

export const InitialComputerSkillState = {
  computerSkillDescription: ''
};

export default function ComputerSkillsStep() {
  const { computerSkillInfo, computerSkillsList } = useResumeGeneratorState();
  const { updateComputerSkillInfo, updateComputerSkillsList } = useResumeGeneratorDispatchers();

  function newEntryStateUpdate(property: string, value: string) {
    updateComputerSkillInfo({ property, value });
  }

  function addNewEntry(entry: ComputerSkillType) {
    updateComputerSkillsList({ entry });
    updateComputerSkillInfo({
      property: '',
      value: '',
      resetValue: true,
      initialState: InitialComputerSkillState
    });
  }

  function removeEntryFromList(index: number) {
    updateComputerSkillsList({ removeEntryAtIndex: index });
  }

  function removeAllEntries() {
    updateComputerSkillsList({ clearList: true });
  }

  return (
    <MultipleEntryList
      title="EDV Kenntnisse"
      dataFieldList={computerSkillsDataFieldList}
      listOfEntries={computerSkillsList}
      newEntryState={computerSkillInfo}
      newEntryStateUpdate={newEntryStateUpdate}
      addNewEntry={addNewEntry}
      removeEntryFromList={removeEntryFromList}
      removeAllEntries={removeAllEntries}
      getColSize={_ => 12}
    />
  );
}
