import DateFnsUtils from '@date-io/date-fns';
import { Tooltip, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import de from 'date-fns/locale/de';
import React from 'react';

interface TooltipWrapperProps {
  title: string | undefined;
  children: any;
}

function TooltipWrapper({ children, title }: TooltipWrapperProps) {
  return title ? (
    <Tooltip title={<Typography align="center">{title}</Typography>}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

interface Props {
  label: string;
  value: Date | undefined;
  onChange: (date: Date) => void;
  showTodayButton?: boolean;
  readOnly?: boolean;
  helperText?: string;
  required?: boolean;
}

export default function DatePicker({ label, value, onChange, showTodayButton, readOnly, helperText, required }: Props) {
  const isEmptyError = required && !!!value;
  const isInvalidValue = value && isNaN(value.getTime());
  const helperTextOnError = isEmptyError ? ' Dies ist ein Pflichtfeld.' : '';
  const helperTextOnInvalidValue = isInvalidValue ? 'Keine gültige Eingabe.' : '';
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
      <TooltipWrapper title={helperText}>
        <KeyboardDatePicker
          style={{ width: '100%', margin: '0px' }}
          margin="normal"
          label={label}
          format="dd.MM.yyyy"
          value={value || null}
          onChange={onChange}
          showTodayButton={showTodayButton}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          cancelLabel="Abbrechen"
          okLabel="Speichern"
          todayLabel="Heute"
          autoOk
          required={required}
          readOnly={readOnly}
          invalidDateMessage="Keine gültige Eingabe."
          emptyLabel={readOnly ? 'heute' : ''}
          disabled={readOnly}
          InputLabelProps={{ shrink: !!value || readOnly }}
          error={isEmptyError || isInvalidValue}
          helperText={helperTextOnError || helperTextOnInvalidValue}
        />
      </TooltipWrapper>
    </MuiPickersUtilsProvider>
  );
}
