import { Paper, Step, StepLabel, Stepper } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import React from 'react';

import { useResumeGeneratorState } from './ResumeGeneratorContextManager';

const styles = createStyles({
  stepperPaperStyleWrap: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    top: '64px',
    left: 0
  }
});

interface Props {
  stepNameList: string[];
  children: React.ReactNode;
  readonly classes: {
    stepperPaperStyleWrap: string;
  };
}

function ResumeGeneratorStepper({ children, classes, stepNameList }: Props) {
  const state = useResumeGeneratorState();
  return (
    <>
      <Paper className={classes.stepperPaperStyleWrap}>
        <Stepper nonLinear activeStep={state.activeStep} alternativeLabel>
          {stepNameList.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
      {children}
    </>
  );
}

export default withStyles(styles)(ResumeGeneratorStepper);
