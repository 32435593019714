import { Box, Grid, Paper } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import ProfileSection from 'shared/components/profilePage/ProfileSection';
import LoadingOverlay from '../../core/components/LoadingOverlay';
import useErrorHandler from '../../core/utils/useErrorHandler';
import { getProgress, ProfileProgress } from '../api/progress';
import MenuAppBar from '../components/MenuAppBar';
import { RegistrationStep } from '../components/RegistrationStepper';

interface Props extends RouteComponentProps {
  steps: Array<RegistrationStep<any, any>>;
  brand: string;
}

const ProfilePage = ({ steps, history, brand }: Props) => {
  const [progress, setProgress] = useState<ProfileProgress>();
  const handleError = useErrorHandler();
  useEffect(() => {
    getProgress()
      .then(progress => {
        setProgress(progress);
        if (progress.state !== 'submitted') {
          history.replace('/register');
        } else if (history.location.pathname === '/register') {
          history.replace('/');
        }
      })
      .catch(handleError);
  }, []);
  return progress ? (
    <Grid item xs={12} sm={10} md={8} lg={7} xl={6} style={{ margin: '1rem 0' }}>
      <Paper elevation={6} style={{ minHeight: '100%' }}>
        <MenuAppBar title="Mein Profil" brand={brand} showBackButton />
        <Box px={3} py={2}>
          {steps
            .filter(step => !step.noProfile)
            .map(step => (
              <ProfileSection<any, any> key={step.title} step={step} />
            ))}
        </Box>
      </Paper>
    </Grid>
  ) : (
    <React.Fragment>
      <LoadingOverlay />
    </React.Fragment>
  );
};

export default ProfilePage;
