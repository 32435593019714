import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDetails, updateDetails } from 'shared/api/profile';
import { RegistrationStep } from 'shared/components/RegistrationStepper';
import { mapDetailsFromFormValues, mapFormValuesFromDetails } from 'shared/components/registrationSteps/personalData';
import PersonalDataForm from 'shared/components/registrationSteps/personalData/PersonalDataForm';
import { ClientPersonalData } from 'shared/components/registrationSteps/personalData/PersonalDataFormFields';

const keyDataStep: RegistrationStep<ClientPersonalData> = {
  title: 'Stammdaten',
  component: props => {
    const { t } = useTranslation(process.env.REACT_APP_MODULE);

    return (
      <PersonalDataForm {...props} enableJobPosition>
        <Box pb={4}>
          <Typography paragraph>
            <Typography variant="subtitle1" color="textSecondary">
              {t('keydatastep.subtitle.steptext1')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t('keydatastep.subtitle.steptext2')}
            </Typography>
          </Typography>

          <Typography paragraph variant="subtitle1" color="textSecondary">
            {t('keydatastep.subtitle.enter-masterdata')}
          </Typography>
        </Box>
      </PersonalDataForm>
    );
  },
  initialState: {},
  onLoad: () => getDetails().then(mapFormValuesFromDetails),
  onSave: (state: ClientPersonalData) => {
    const projectNumber = new URLSearchParams(window.location.search).get('jobNumber')
    updateDetails(mapDetailsFromFormValues(state, projectNumber ? projectNumber : undefined))}
};

export default keyDataStep;
