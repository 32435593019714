import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationStep } from 'shared/components/RegistrationStepper';
import Summary from 'shared/components/registrationSteps/summary/Summary';

const summaryStep: RegistrationStep<any> = {
  title: 'Registrierung abgeschlossen',
  component: () => {
    const { t } = useTranslation(process.env.REACT_APP_MODULE);
    return (
      <Summary websiteLink="https://www.match-flix.de/">
        <Typography paragraph align="center" color="textSecondary" variant="subtitle1">
          {t('summarystep.subtitle.steptext1')}
        </Typography>

        <Typography paragraph align="center" color="textSecondary" variant="subtitle1">
          {t('summarystep.subtitle.steptext2')}
        </Typography>

        <Typography paragraph align="center" color="textSecondary" variant="subtitle1">
          {t('summarystep.subtitle.steptext3')}
        </Typography>
      </Summary>
    );
  },
  saveProgress: false,
  noProfile: true,
  noBackButton: true
};

export default summaryStep;
