import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';

import { useResumeGeneratorState } from './ResumeGeneratorContextManager';

interface Props {
  stepContentList: React.ReactNodeArray;
}

export default function ActiveStepContent({ stepContentList }: Props) {
  const state = useResumeGeneratorState();
  return (
    <Container maxWidth="md">
      <Grid container spacing={1} style={{ paddingTop: '130px' }}>
        <Grid item md={12}>
          <Box>{stepContentList[state.activeStep]}</Box>
        </Grid>
      </Grid>
    </Container>
  );
}
