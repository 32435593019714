const COUNTRY_NAMES = [
  'Deutschland',
  'Schweiz',
  'Österreich',
  'Afghanistan',
  'Ägypten',
  'Aland',
  'Albanien',
  'Algerien',
  'Amerikanisch-Samoa',
  'Amerikanische Jungferninseln',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarktis',
  'Antigua und Barbuda',
  'Äquatorialguinea',
  'Argentinien',
  'Armenien',
  'Aruba',
  'Ascension',
  'Aserbaidschan',
  'Äthiopien',
  'Australien',
  'Bahamas',
  'Bahrain',
  'Bangladesch',
  'Barbados',
  'Belgien',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivien',
  'Bosnien und Herzegowina',
  'Botswana',
  'Bouvetinsel',
  'Brasilien',
  'Brunei',
  'Bulgarien',
  'Burkina Faso',
  'Burundi',
  'Chile',
  'China',
  'Cookinseln',
  'Costa Rica',
  "Cote d'Ivoire",
  'Dänemark',
  'Diego Garcia',
  'Dominica',
  'Dominikanische Republik',
  'Dschibuti',
  'Ecuador',
  'El Salvador',
  'Eritrea',
  'Estland',
  'Europäische Union',
  'Falklandinseln',
  'Färöer',
  'Fidschi',
  'Finnland',
  'Frankreich',
  'Französisch-Guayana',
  'Französisch-Polynesien',
  'Gabun',
  'Gambia',
  'Georgien',
  'Ghana',
  'Gibraltar',
  'Grenada',
  'Griechenland',
  'Grönland',
  'Großbritannien',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard und McDonaldinseln',
  'Honduras',
  'Hongkong',
  'Indien',
  'Indonesien',
  'Irak',
  'Iran',
  'Irland',
  'Island',
  'Israel',
  'Italien',
  'Jamaika',
  'Japan',
  'Jemen',
  'Jersey',
  'Jordanien',
  'Kaimaninseln',
  'Kambodscha',
  'Kamerun',
  'Kanada',
  'Kanarische Inseln',
  'Kap Verde',
  'Kasachstan',
  'Katar',
  'Kenia',
  'Kirgisistan',
  'Kiribati',
  'Kokosinseln',
  'Kolumbien',
  'Komoren',
  'Kongo',
  'Kroatien',
  'Kuba',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Lettland',
  'Libanon',
  'Liberia',
  'Libyen',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Macao',
  'Madagaskar',
  'Malawi',
  'Malaysia',
  'Malediven',
  'Mali',
  'Malta',
  'Marokko',
  'Marshallinseln',
  'Martinique',
  'Mauretanien',
  'Mauritius',
  'Mayotte',
  'Mazedonien',
  'Mexiko',
  'Mikronesien',
  'Moldawien',
  'Monaco',
  'Mongolei',
  'Montserrat',
  'Mosambik',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Neukaledonien',
  'Neuseeland',
  'Neutrale Zone',
  'Nicaragua',
  'Niederlande',
  'Niederländische Antillen',
  'Niger',
  'Nigeria',
  'Niue',
  'Nordkorea',
  'Nördliche Marianen',
  'Norfolkinsel',
  'Norwegen',
  'Oman',
  'Pakistan',
  'Palästina',
  'Palau',
  'Panama',
  'Papua-Neuguinea',
  'Paraguay',
  'Peru',
  'Philippinen',
  'Pitcairninseln',
  'Polen',
  'Portugal',
  'Puerto Rico',
  'Réunion',
  'Ruanda',
  'Rumänien',
  'Russische Föderation',
  'Salomonen',
  'Sambia',
  'Samoa',
  'San Marino',
  'São Tomé und Príncipe',
  'Saudi-Arabien',
  'Schweden',
  'Senegal',
  'Serbien und Montenegro',
  'Seychellen',
  'Sierra Leone',
  'Simbabwe',
  'Singapur',
  'Slowakei',
  'Slowenien',
  'Somalia',
  'Spanien',
  'Sri Lanka',
  'St. Helena',
  'St. Kitts und Nevis',
  'St. Lucia',
  'St. Pierre und Miquelon',
  'St. Vincent/Grenadinen (GB)',
  'Südafrika, Republik',
  'Sudan',
  'Südkorea',
  'Suriname',
  'Svalbard und Jan Mayen',
  'Swasiland',
  'Syrien',
  'Tadschikistan',
  'Taiwan',
  'Tansania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad und Tobago',
  'Tristan da Cunha',
  'Tschad',
  'Tschechische Republik',
  'Tunesien',
  'Türkei',
  'Turkmenistan',
  'Turks- und Caicosinseln',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'Ungarn',
  'Uruguay',
  'Usbekistan',
  'Vanuatu',
  'Vatikanstadt',
  'Venezuela',
  'Vereinigte Arabische Emirate',
  'Vereinigte Staaten von Amerika',
  'Vietnam',
  'Wallis und Futuna',
  'Weihnachtsinsel',
  'Weißrussland',
  'Westsahara',
  'Zentralafrikanische Republik',
  'Zypern'
];

export default COUNTRY_NAMES;
