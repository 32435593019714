import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/styles';
import React from 'react';

const styles = createStyles({
  appBarDrawer: {
    backgroundColor: '#222'
  },
  appBarTitle: {
    marginLeft: '1em',
    flex: 1
  }
});

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  readonly classes: {
    appBarDrawer: string;
    appBarTitle: string;
  };
}

function FullscreenDialog({ children, classes, onClose, open }: Props) {
  const renderAppBar = (
    <AppBar className={classes.appBarDrawer} position="sticky">
      <Toolbar>
        <Typography variant="h6" component="h6" className={classes.appBarTitle}>
          Lebenslauf-Generator
        </Typography>
        <IconButton color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      {renderAppBar}
      {children}
    </Dialog>
  );
}

export default withStyles(styles)(FullscreenDialog);
