import { performDelete, performGet, performPost } from 'core/apiUtils';

export interface UploadedFile {
  id: string;
  documentName: string;
  documentType?: string;
}

export async function listDocuments(): Promise<UploadedFile[]> {
  return await performGet('/documents');
}

interface UploadDocumentResponse {
  documentId: string;
}

export class DuplicateDocumentError extends Error {}
// tslint:disable-next-line: max-classes-per-file
export class InvalidMimeTypeError extends Error {}

export async function uploadDocument(file: File, documentType?: string): Promise<UploadedFile> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    if (documentType) {
      formData.append('documentType', documentType);
    }
    const response = await performPost<UploadDocumentResponse>('/documents', formData);
    return { id: response.documentId, documentName: file.name, documentType };
  } catch (err) {
    if (err.message === '422') {
      throw new DuplicateDocumentError();
    } else if (err.message === '415') {
      throw new InvalidMimeTypeError();
    }
    throw new Error();
  }
}

export async function deleteDocument(documentId: string) {
  await performDelete(`/documents/${documentId}`);
}

interface GetDocumentResponse {
  contentUrl: string;
  contentType: string;
}

export async function getDocument(documentId: string): Promise<GetDocumentResponse> {
  return await performGet(`/documents/${documentId}`);
}

export const DOCUMENT_TYPE_CV = 'CV';
