import { keycloak } from './keycloak/keycloak';

export const BASE_URL = `${process.env.REACT_APP_BACKEND_URL || ''}/externalProfile/${process.env.REACT_APP_MODULE}`;

type Payloads = FormData | File | File[] | object;

function doFetch(relativeUrl: string, payload?: Payloads, method?: string) {
  const init = optionsForPayload(payload);
  const fetchInit = {
    ...init,
    method: init.method || method,
    headers: { ...(init || {}).headers, Authorization: `Bearer ${keycloak.token}` }
  };
  return fetch(`${BASE_URL}${relativeUrl}`, fetchInit).then(res => {
    if (!res.ok) {
      throw new Error(`${res.status}`);
    }
    return res.text().then(data => (data ? JSON.parse(data) : undefined));
  });
}

function optionsForPayload(payload?: Payloads) {
  if (!payload) {
    return {};
  }
  if (payload instanceof FormData) {
    return { body: payload };
  }
  if (payload instanceof File || Array.isArray(payload)) {
    return optionsForFile(payload);
  }
  return optionsForJson(payload);
}

function optionsForJson(payload: object): RequestInit {
  return {
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(payload)
  };
}

function optionsForFile(payload: File | File[]): RequestInit {
  const formData = new FormData();
  if (Array.isArray(payload)) {
    payload.forEach((file, idx) => formData.append(`file-${idx}`, file));
  } else {
    formData.append('file-0', payload);
  }
  return {
    body: formData
  };
}

export function performGet<T>(url: string): Promise<T> {
  return doFetch(url);
}

export function performPost<T>(url: string, payload?: Payloads): Promise<T> {
  return doFetch(url, payload, 'POST');
}

export function performPut<T>(url: string, payload?: Payloads): Promise<T> {
  return doFetch(url, payload, 'PUT');
}

export function performDelete(url: string, payload?: Payloads): Promise<void> {
  return doFetch(url, payload, 'DELETE');
}
