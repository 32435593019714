import LoadingOverlay from 'core/components/LoadingOverlay';
import * as React from 'react';
import { KeycloakProvider } from 'react-keycloak';
import { initialize, keycloak } from './keycloak';

interface Props {
  children: React.ReactNode;
  clientId: string;
}

// this needs to be outside of the hot-reloaded App component, otherwise login information is lost on reload
// and the whole app refreshes due to the Keycloak redirect
class KeycloakWrapper extends React.Component<Props, { initialized?: boolean; error?: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    initialize(props.clientId)
      .then(() => this.setState({ initialized: true }))
      .catch(err => {
        this.setState({ error: true });
        // tslint:disable-next-line:no-console
        console.error(err);
      });
  }

  render() {
    const { children } = this.props;
    if (this.state.error) {
      return <div>Initialization failed. Check console.</div>;
    }
    if (!this.state.initialized) {
      return <LoadingOverlay />;
    }
    return (
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={{ onLoad: 'login-required', checkLoginIframe: process.env.NODE_ENV === 'production' }}
        LoadingComponent={<LoadingOverlay /> as any}
      >
        {children}
      </KeycloakProvider>
    );
  }
}

export default KeycloakWrapper;
