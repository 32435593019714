import { Typography } from '@material-ui/core';
import ConfirmationDialogWrapper from 'core/components/generic_components/DialogWrapper';
import StepperWithControls, { StepAttributes } from 'core/components/stepper/StepperWithControls';
import useErrorHandler from 'core/utils/useErrorHandler';
import { useNestedState } from 'core/utils/useNestedState';
import React, { useEffect, useState } from 'react';
import { updateProgress } from '../api/progress';

const DOCUMENT_STEP = 1;

export interface StepComponentProps<S, V = string> {
  state: S;
  setState: React.Dispatch<React.SetStateAction<S>>;
  readOnly?: boolean;
  validationResult?: V;
  enableResumeDropZone?: boolean;
  children?: React.ReactNode;
  isProfileSection?: boolean;
}

export interface RegistrationStep<S, V = string> extends StepAttributes {
  component: React.ComponentType<StepComponentProps<S, V>>;
  saveProgress?: boolean;
  noProfile?: boolean;
  initialState?: S;
  onLoad?: () => S | Promise<S>;
  onSave?: (state: S) => void | Promise<void>;
  /**
   * @returns validation errors for display, or false for generic error message
   */
  onValidate?: (state: S) => V | true | undefined;
}

interface Props {
  steps: Array<RegistrationStep<any, any>>;
  progressStep: number | undefined;
  brand: string;
}

const RegistrationStepper = ({ steps, progressStep, brand }: Props) => {
  const [stepState, setStepState] = useState({
    activeStep: 0,
    componentState: steps[0].initialState
  });
  const [componentState, setComponentState] = useNestedState(stepState, setStepState, 'componentState');
  const [maxStep, setMaxStep] = useState(0);
  const [validationResult, setValidationResult] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const handleError = useErrorHandler();
  useEffect(() => {
    switchStep(progressStep || 0);
  }, []);

  async function loadStepData<S>(step: RegistrationStep<S>): Promise<S | undefined> {
    if (step.onLoad) {
      return (await step.onLoad()) || step.initialState;
    }
    return undefined;
  }

  async function switchStep(stepNumber: number, save?: boolean) {
    const currentStep = steps[stepState.activeStep];
    const newStep = steps[stepNumber];

    try {
      setLoading(true);
      if (save && currentStep.onSave) {
        let validationResult;
        if (currentStep.onValidate) {
          validationResult = currentStep.onValidate(componentState);
        }
        if (validationResult !== true && validationResult !== undefined) {
          setValidationResult(validationResult);
          return;
        }
        await currentStep.onSave(componentState);
      }
      setValidationResult(undefined);

      const nextComponentState = await loadStepData(newStep);
      setStepState({
        activeStep: stepNumber,
        componentState: nextComponentState
      });

      if (stepNumber > maxStep) {
        setMaxStep(stepNumber);
        if (newStep.saveProgress !== false) {
          return updateProgress({ state: 'registration', step: stepNumber });
        }
      }
    } finally {
      setLoading(false);
    }
  }

  const handleNext = () => {
    switchStep(stepState.activeStep + 1, true).catch(handleError);
  };
  const handlePrev = () => {
    switchStep(stepState.activeStep - 1).catch(handleError);
  };
  const StepComponent = steps[stepState.activeStep].component;

  const renderConfirmationDialog = () => {
    return (
      <ConfirmationDialogWrapper
        open={confirmationDialogOpen}
        dialogTitle="Kein Lebenslauf vorhanden"
        handleClose={() => setConfirmationDialogOpen(false)}
        handleSubmit={() => {
          setConfirmationDialogOpen(false);
          handleNext();
        }}
        closeButtonName="Abbrechen"
        submitButtonName="Ohne Lebenslauf fortfahren"
        dialogContentText={
          <React.Fragment>
            <Typography paragraph>
              Ihren Lebenslauf hochzuladen erhöht die Chancen von Unternehmen gefunden zu werden.
            </Typography>
          </React.Fragment>
        }
      />
    );
  };

  const isDocumentStep = () => {
    return stepState.activeStep === DOCUMENT_STEP;
  };

  const profileHasResume = () => {
    return !!(componentState.find && componentState.find((entry: any) => entry.documentType === 'CV'));
  };

  const isMatchflixOrPersTotalOrSenator = () => {
    return brand === 'matchflix' || 'personaltotal' || 'senator';
  };


  return (
    <StepperWithControls
      steps={steps}
      activeStep={stepState.activeStep}
      loading={loading}
      onNext={
        isDocumentStep() && !profileHasResume() && isMatchflixOrPersTotalOrSenator() 
          ? () => {
              console.log('componentState', componentState);
              setConfirmationDialogOpen(true);
            }
          : handleNext
      }
      onBack={handlePrev}
    >
      <StepComponent validationResult={validationResult} state={componentState} setState={setComponentState} />
      {renderConfirmationDialog()}
    </StepperWithControls>
  );
};

export default RegistrationStepper;
