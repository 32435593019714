import { FormControl, Grid, TextField } from '@material-ui/core';
import React from 'react';

interface Props {
  value?: string;
  inputType?: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  variant?: any;
  onChange?: (value: string) => void;
  helperText?: string;
  InputProps?: object;
}

const FormInputField = ({
  value,
  inputType = 'text',
  required,
  label,
  onChange,
  disabled,
  variant,
  helperText,
  InputProps
}: Props) => {
  return (
    <Grid item md={6} xs={12}>
      <FormControl required fullWidth>
        <TextField
          color="primary"
          label={label}
          value={value || ''}
          onChange={event => {
            if (onChange) {
              onChange(event.target.value);
            }
          }}
          fullWidth
          required={required}
          type={inputType}
          variant={variant || 'standard'}
          disabled={disabled}
          helperText={helperText}
          InputProps={InputProps}
        />
      </FormControl>
    </Grid>
  );
};

export default React.memo(FormInputField);
