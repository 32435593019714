import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import KeycloakWrapper from 'core/keycloak/KeycloakWrapper';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

interface Props {
  moduleId: string;
  theme: Theme;
}

const ExternalProfileApp: React.FC<Props> = ({ moduleId, theme, children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <KeycloakWrapper clientId={moduleId}>
          <Router>{children}</Router>
        </KeycloakWrapper>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default ExternalProfileApp;
