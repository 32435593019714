import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  DeleteForever as DeleteForeverIcon,
  ExitToApp as ExitToAppIcon,
  Settings as SettingsIcon
} from '@material-ui/icons';
import { keycloak } from 'core/keycloak/keycloak';
import * as React from 'react';
import { useState } from 'react';
import DeleteAccountModal from './DeleteAccountDialog';

interface Props {
  title?: React.ReactNode;
  brand: string;
  showBackButton?: boolean;
  width?: any;
}

const MenuAppBar = ({ title, brand, showBackButton, width }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);
  const menuIconStyle = { marginRight: '10px' };
  const isExtraSmall = isWidthDown('xs', width);

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const accountOfCurrentUserIconButton = (
    <IconButton
      aria-label="Account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="primary"
    >
      <AccountCircleIcon fontSize="large" />
    </IconButton>
  );

  const backToMatchflixButton =
    brand === 'matchflix' && showBackButton ? (
      <Button
        href="https://match-flix.de"
        color="secondary"
        variant="contained"
        size={isExtraSmall ? 'small' : 'large'}
      >
        <ArrowBackIcon style={{ marginRight: '5px' }} />
        Zurück zu Match-Flix
      </Button>
    ) : (
      <Typography variant="h6">{title}</Typography>
    );

  const settingsMenuItem = (
    <MenuItem key="settingsMenuItem" component="a" href={keycloak.createAccountUrl()}>
      <SettingsIcon fontSize="small" style={menuIconStyle} /> Login-Daten bearbeiten
    </MenuItem>
  );

  const logoutMenuItem = (
    <MenuItem
      key="logoutMenuItem"
      divider
      onClick={() => {
        brand === 'matchflix' ? keycloak.logout({ redirectUri: 'https://match-flix.de' }) : keycloak.logout();
      }}
    >
      <ExitToAppIcon fontSize="small" style={menuIconStyle} /> Abmelden
    </MenuItem>
  );

  const deleteAccountMenuItem = (
    <MenuItem
      key="deleteAccountMenuItem"
      color="primary"
      onClick={() => {
        handleClose();
        setDeleteAccountOpen(true);
      }}
    >
      <DeleteForeverIcon fontSize="small" style={menuIconStyle} />
      <Typography color="error"> Account löschen</Typography>
    </MenuItem>
  );

  const menuItemList = [settingsMenuItem, logoutMenuItem, deleteAccountMenuItem];

  const userMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      onClose={handleClose}
    >
      {menuItemList}
    </Menu>
  );

  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Toolbar>
        {backToMatchflixButton}
        <div style={{ marginLeft: 'auto' }}>
          {accountOfCurrentUserIconButton}
          {userMenu}
        </div>
      </Toolbar>
      <DeleteAccountModal open={deleteAccountOpen} onClose={() => setDeleteAccountOpen(false)} brand={brand} />
    </AppBar>
  );
};

export default withWidth()(MenuAppBar);
