export const personalDataFieldList = [
  {
    fieldId: 'firstName',
    label: 'Vorname',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'lastName',
    label: 'Nachname',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'dateOfBirth',
    label: 'Geburtsdatum',
    fieldType: 'date',
    required: true
  },
  {
    fieldId: 'email',
    label: 'Email-Adresse',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'phone',
    label: 'Telefonnummer',
    fieldType: 'textField'
  },
  {
    fieldId: 'mobile',
    label: 'Mobil',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'city',
    label: 'Ort',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'postalCode',
    label: 'Postleitzahl',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'street',
    label: 'Straße',
    fieldType: 'textField',
    required: true
  },
  {
    fieldId: 'maritalStatus',
    label: 'Familienstand',
    fieldType: 'dropDown',
    listOfSelections: ['Ledig', 'Verheiratet']
  }
];

export const positionDataFieldList: DataFieldType[] = [
  {
    fieldId: 'position',
    label: 'Position',
    fieldType: 'textField'
  },
  {
    fieldId: 'companyName',
    label: 'Unternehmen',
    fieldType: 'textField'
  },
  {
    fieldId: 'fromDate',
    label: 'Von',
    fieldType: 'date'
  },
  {
    fieldId: 'untilDate',
    label: 'Bis',
    fieldType: 'date',
    helperText: 'Lassen Sie diese Feld leer, falls diese Anstellung / Position noch aktuell besteht'
  },
  {
    fieldId: 'positionDescription',
    label: 'Beschreibung',
    fieldType: 'description'
  }
];

export const educationDataFieldList: DataFieldType[] = [
  {
    fieldId: 'education',
    label: 'Ausbildung',
    fieldType: 'textField'
  },
  {
    fieldId: 'institute',
    label: 'Institut',
    fieldType: 'textField'
  },
  {
    fieldId: 'educationType',
    label: 'Typ',
    fieldType: 'textField',
    helperText: 'z.B. : Ausbildung, Studium, Praktikum'
  },
  {
    fieldId: 'educationFromDate',
    label: 'Von',
    fieldType: 'date'
  },
  {
    fieldId: 'educationUntilDate',
    label: 'Bis',
    fieldType: 'date',
    helperText: 'Lassen Sie diese Feld leer, falls Sie sich noch in der Ausbildung befinden'
  },
  {
    fieldId: 'educationDescription',
    label: 'Beschreibung',
    fieldType: 'description'
  }
];

export const languageSkillsDataFieldList: DataFieldType[] = [
  {
    fieldId: 'languageName',
    label: 'Sprache',
    fieldType: 'textField'
  },
  {
    fieldId: 'skillLevel',
    label: 'Grad der Beherrschung',
    fieldType: 'dropDown',
    listOfSelections: [
      'Grundkenntnisse',
      'konversationssicher oder gute Kenntnisse',
      'fließend',
      'verhandlungssicher',
      'Muttersprache'
    ]
  }
];

export const computerSkillsDataFieldList: DataFieldType[] = [
  {
    fieldId: 'computerSkillDescription',
    label: 'Bitte EDV Kenntnisse angeben',
    fieldType: 'textField',
    helperText: 'z.B. : Microsoft Office oder SAP/R3'
  }
];

export const miscellaneousDataFieldList: DataFieldType[] = [
  {
    fieldId: 'miscDescription',
    label: 'Sonstiges',
    fieldType: 'textField',
    helperText: 'z.B. : Musik, Spezielle Hobbys, etc'
  }
];

export interface DataFieldType {
  fieldId: string;
  label: string;
  fieldType: 'date' | 'textField' | 'description' | 'dropDown';
  listOfSelections?: string[];
  helperText?: string;
  required?: boolean;
}
