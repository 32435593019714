import { Box, Grid, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ResumeIcon from '@material-ui/icons/AssignmentInd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { downloadFile } from 'core/utils/files';
import { useSnackbar } from 'notistack';
import React from 'react';

import DropzoneContainer from '../../../core/components/generic_components/DropzoneContainer';
import { FileListingContainer } from '../../../core/components/generic_components/FileListingContainer';
import {
  deleteDocument,
  DOCUMENT_TYPE_CV,
  DuplicateDocumentError,
  getDocument,
  listDocuments,
  uploadDocument,
  UploadedFile
} from '../../api/document';
import { StepComponentProps } from '../RegistrationStepper';
import ResumeGenerator from './ResumeGenerator';

const ResumeUpload = ({ state, setState, readOnly, validationResult }: StepComponentProps<UploadedFile[], false>) => {
  const resumeFile = state.filter(entry => entry.documentType === DOCUMENT_TYPE_CV)[0];
  const { enqueueSnackbar } = useSnackbar();
  const [isResumeGeneratorOpen, setIsResumeGeneratorOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const onDropAction = async (files: File[]) => {
    const file = files[0];
    if (file) {
      if (resumeFile) {
        await deleteDocument(resumeFile.id).catch(err => {
          enqueueSnackbar('Das Dokument konnte nicht gelöscht werden.', {
            variant: 'error'
          });
        });
      }
      const resume = await uploadDocument(file, DOCUMENT_TYPE_CV).catch(err => {
        const message =
          err instanceof DuplicateDocumentError
            ? `Der Lebenslauf mit Namen '${file.name}' existiert bereits.`
            : `Die Datei '${file.name}' konnte nicht hochgeladen werden.`;
        enqueueSnackbar(message, {
          variant: 'error'
        });
      });
      setState([
        ...(resumeFile ? state.filter(entry => entry.id !== resumeFile.id) : state),
        ...(resume ? [resume] : [])
      ]);
    }
  };

  const checkForResumeFile = async () => {
    const documentList = await listDocuments();
    setState([...documentList]);
  };

  const renderUploadGenerate = () => {
    return (
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <DropzoneContainer
            icon={<CloudUploadIcon fontSize="large" color={readOnly ? 'disabled' : 'primary'} />}
            isDragRejectIcon={<NotInterestedIcon fontSize="large" color="primary" />}
            isDragAcceptIcon={<CheckCircleIcon fontSize="large" color="primary" />}
            loadingIcon={<CircularProgress />}
            infoText="Lebenslauf hochladen"
            rejectText="Dieses Format wird nicht unterstützt"
            acceptText="Lebenslauf hochladen"
            loadingText="Dokument-Upload läuft..."
            invalid={validationResult === false}
            disabled={readOnly}
            onDrop={onDropAction}
          />
        </Grid>
        <Grid item sm={6}>
          <Paper
            onClick={readOnly ? undefined : () => setIsResumeGeneratorOpen(true)}
            style={readOnly ? {} : { cursor: 'pointer' }}
          >
            <Box p={5} style={{ textAlign: 'center' }}>
              <ResumeIcon fontSize="large" color={readOnly ? 'disabled' : 'primary'} />
              <Typography color={readOnly ? 'textSecondary' : 'primary'}>Lebenslauf-Generator starten</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const onDownload = (id: string, name: string) => {
    getDocument(id)
      .then(doc => downloadFile(name, doc.contentUrl))
      .catch(() => enqueueSnackbar(`Die Datei ${name} konnte nicht heruntergeladen werden.`));
  };

  const getFileUrl = (id: string, name: string) => {
    return getDocument(id)
      .then(file => file.contentUrl)
      .catch(() => enqueueSnackbar(`Die Datei ${name} wurde nicht gefunden.`));
  };

  const renderDownloadResume = () => {
    return (
      <FileListingContainer
        files={[resumeFile]}
        emptyFileListText="Keine Dokumente vorhanden"
        onDelete={
          readOnly
            ? undefined
            : async (setDeleteActionLoading: (value: boolean) => void, fileId: string) => {
                try {
                  setDeleteActionLoading(true);
                  await deleteDocument(fileId);
                  setDeleteActionLoading(false);
                  setState(state.filter(file => file.id !== fileId));
                  setIsEdit(false);
                  enqueueSnackbar(`Das Dokument wurde erfolgreich gelöscht.`, { variant: 'success' });
                } catch (err) {
                  enqueueSnackbar('Das Dokument konnte nicht gelöscht werden.');
                }
              }
        }
        onEdit={
          readOnly
            ? undefined
            : () => {
                setIsEdit(true);
                setIsResumeGeneratorOpen(true);
              }
        }
        onDownload={onDownload}
        getFileUrl={getFileUrl}
      />
    );
  };

  return (
    <>
      <Box pb={2}>
        <div>{resumeFile ? renderDownloadResume() : renderUploadGenerate()}</div>
        {validationResult === false && (
          <div>
            <Typography align="center" color="error">
              Bitte laden Sie Ihren Lebenslauf hoch.
            </Typography>
          </div>
        )}
      </Box>
      <ResumeGenerator
        open={isResumeGeneratorOpen}
        onClose={() => {
          setIsResumeGeneratorOpen(false);
          checkForResumeFile();
        }}
        isEdit={isEdit}
      />
    </>
  );
};

export default ResumeUpload;
