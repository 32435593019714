import React from 'react';

import { languageSkillsDataFieldList } from './inputFieldList';
import MultipleEntryList from './MultipleEntryList';
import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';

export interface LanguageSkillsType {
  languageName?: string;
  skillLevel?: string;
}

export const InitialLanguageSkillsState = {
  languageName: '',
  skillLevel: ''
};

export default function LanguageSkillsStep() {
  const state = useResumeGeneratorState();
  const dispatchers = useResumeGeneratorDispatchers();

  function newEntryStateUpdate(property: string, value: string) {
    dispatchers.updateLanguageSkillInfo({ property, value });
  }

  function addNewEntry(entry: LanguageSkillsType) {
    dispatchers.updateLanguageSkillsList({ entry });
    dispatchers.updateLanguageSkillInfo({
      property: '',
      value: '',
      resetValue: true,
      initialState: InitialLanguageSkillsState
    });
  }

  function removeEntryFromList(index: number) {
    dispatchers.updateLanguageSkillsList({ removeEntryAtIndex: index });
  }

  function removeAllEntries() {
    dispatchers.updateLanguageSkillsList({ clearList: true });
  }

  return (
    <MultipleEntryList
      title="Sprachen"
      dataFieldList={languageSkillsDataFieldList}
      listOfEntries={state.languageSkillsList}
      newEntryState={state.languageSkillInfo}
      newEntryStateUpdate={newEntryStateUpdate}
      addNewEntry={addNewEntry}
      removeEntryFromList={removeEntryFromList}
      removeAllEntries={removeAllEntries}
    />
  );
}
