import { Box, Typography } from '@material-ui/core';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import DesktopStepDisplay from './DesktopStepDisplay';
import StepperControls from './StepperControls';

interface Props {
  steps: StepAttributes[];
  activeStep?: number;
  children: React.ReactNode;
  loading?: boolean;
  onNext: () => void;
  onBack: () => void;
}

export interface StepAttributes {
  title: string;
  noBackButton?: boolean;
}

const StepperWithControls = ({ steps, activeStep = 0, children, loading, onNext, onBack }: Props) => (
  <div>
    <DesktopStepDisplay steps={steps} activeStep={activeStep} />
    <form
      onSubmit={ev => {
        ev.preventDefault();
        onNext();
      }}
      onReset={ev => {
        ev.preventDefault();
        onBack();
      }}
    >
      <Box px={3} py={2}>
        <LoadingOverlay active={loading} spinner text="Lädt...">
          <Box pb={3} component="div">
            <Typography align="center" color="primary" variant="h5">
              {steps[activeStep].title}
            </Typography>
          </Box>
          <Box>{children}</Box>
        </LoadingOverlay>
      </Box>
      <StepperControls steps={steps} activeStep={activeStep} disabled={loading} />
    </form>
  </div>
);

export default StepperWithControls;
