import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import useErrorHandler from 'core/utils/useErrorHandler';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { RegistrationStep } from '../RegistrationStepper';

interface Props<S, V> {
  step: RegistrationStep<S, V>;
}

const ProfileSection = <S extends {}, V>({ step }: Props<S, V>) => {
  const handleError = useErrorHandler();
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(step.initialState);
  const [validationResult, setValidationResult] = useState<V>();
  const StepComponent = step.component;
  useEffect(() => {
    if (step.onLoad) {
      Promise.resolve(step.onLoad()).then(setState);
    }
  }, []);

  async function doSave() {
    setLoading(true);
    try {
      if (step.onSave) {
        let validationResult: V | true | undefined;
        if (step.onValidate) {
          validationResult = step.onValidate(state!);
        }
        if (validationResult !== true && validationResult !== undefined) {
          setValidationResult(validationResult);
          return;
        }
        await step.onSave(state!);
      }
      setReadOnly(true);
      setValidationResult(undefined);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Typography variant="h5">{step.title}</Typography>
      <Divider />
      <Box py={3} className="pl-4-md">
        <form
          onSubmit={ev => {
            ev.preventDefault();
            doSave();
          }}
        >
          <LoadingOverlay active={loading} spinner text="Lädt...">
            <StepComponent
              readOnly={readOnly || loading}
              state={state!}
              setState={setState}
              validationResult={validationResult}
              isProfileSection
            />
          </LoadingOverlay>
          <Box pt={3}>
            <Grid container justify="flex-end">
              <Grid item>
                {readOnly ? (
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={loading}
                    onClick={ev => {
                      ev.preventDefault();
                      setReadOnly(false);
                    }}
                  >
                    Bearbeiten
                  </Button>
                ) : (
                  <Button type="submit" color="primary" variant="contained" disabled={loading}>
                    Speichern
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default ProfileSection;
