import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { downloadFile } from 'core/utils/files';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import DropzoneContainer from '../../../core/components/generic_components/DropzoneContainer';
import { FileListingContainer } from '../../../core/components/generic_components/FileListingContainer';
import {
  deleteDocument,
  DOCUMENT_TYPE_CV,
  DuplicateDocumentError,
  getDocument,
  uploadDocument,
  UploadedFile
} from '../../api/document';
import { StepComponentProps } from '../RegistrationStepper';

const GenericDocumentManagement = ({
  state,
  setState,
  readOnly,
  validationResult
}: StepComponentProps<UploadedFile[], false>) => {
  const attachmentFiles = state.filter(entry => entry.documentType !== DOCUMENT_TYPE_CV);
  const { enqueueSnackbar } = useSnackbar();

  const onDownload = (id: string, name: string) => {
    getDocument(id)
      .then(doc => downloadFile(name, doc.contentUrl))
      .catch(() => enqueueSnackbar(`Die Datei ${name} konnte nicht heruntergeladen werden.`));
  };

  const getFileUrl = (id: string, name: string) => {
    return getDocument(id)
      .then(file => file.contentUrl)
      .catch(() => enqueueSnackbar(`Die Datei ${name} wurde nicht gefunden.`));
  };

  return (
    <div style={{ width: '100%' }}>
      {!readOnly && (
        <Grid item xs={12}>
          <DropzoneContainer
            icon={<CloudUploadIcon fontSize="large" color="primary" />}
            isDragRejectIcon={<NotInterestedIcon fontSize="large" color="primary" />}
            isDragAcceptIcon={<CheckCircleIcon fontSize="large" color="primary" />}
            loadingIcon={<CircularProgress />}
            infoText="Dokument(e) hochladen"
            rejectText="Dieses Format wird nicht unterstützt"
            acceptText="Dokument(e) hochladen"
            loadingText="Dokument-Upload läuft..."
            invalid={validationResult === false}
            onDrop={async (files: File[]) => {
              const documents = await Promise.all(
                files.map(file =>
                  uploadDocument(file).catch(err => {
                    const message =
                      err instanceof DuplicateDocumentError
                        ? `Ein Dokument mit Namen '${file.name}' existiert bereits.`
                        : `Die Datei '${file.name}' konnte nicht hochgeladen werden.`;
                    enqueueSnackbar(message, {
                      variant: 'error'
                    });
                  })
                )
              );
              setState([...state, ...(documents.filter(Boolean) as UploadedFile[])]);
            }}
          />
          {validationResult === false && (
            <Grid item xs={12}>
              <Typography align="center" color="error">
                Bitte laden Sie mindestens ein Dokument hoch.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        <FileListingContainer
          files={attachmentFiles}
          emptyFileListText="Keine Dokumente vorhanden"
          onDelete={
            readOnly
              ? undefined
              : async (setDeleteActionLoading: (value: boolean) => void, fileId) => {
                  try {
                    setDeleteActionLoading(true);
                    await deleteDocument(fileId);
                    setDeleteActionLoading(false);
                    setState(state.filter(file => file.id !== fileId));
                    enqueueSnackbar(`Das Dokument wurde erfolgreich gelöscht.`, { variant: 'success' });
                  } catch (err) {
                    enqueueSnackbar('Das Dokument konnte nicht gelöscht werden.');
                  }
                }
          }
          onDownload={onDownload}
          getFileUrl={getFileUrl}
        />
      </Grid>
    </div>
  );
};

export default GenericDocumentManagement;
