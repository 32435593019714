import { FormControl, Grid, InputLabel, NativeSelect } from '@material-ui/core';
import * as React from 'react';
import FormInputField from './FormInputField';

interface Props {
  readOnly?: boolean;
  value?: string;
  optionList: string[];
  onChange: (value: string) => void;
  fieldName: string;
}

const DropDownSelectField = ({ readOnly, value, onChange, optionList, fieldName }: Props) => {
  if (readOnly) {
    return (
      <FormInputField
        label={fieldName}
        required
        value={value || ''}
        InputProps={{ readOnly: true, disableUnderline: true }}
      />
    )
  }
  return (
    <Grid item md={6} xs={12}>
      <FormControl required fullWidth>
        <InputLabel shrink>{fieldName}</InputLabel>
        <NativeSelect value={value} onChange={event => onChange(event.target.value)}>
          <option value="" />
          {optionList.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Grid>
  );
};

export default React.memo(DropDownSelectField);
