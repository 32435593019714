import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  icon: React.ReactNode;
  isDragAcceptIcon?: React.ReactNode;
  isDragRejectIcon?: React.ReactNode;
  loadingIcon?: React.ReactNode;
  infoText: string;
  rejectText: string;
  acceptText: string;
  loadingText: string;
  onDrop: (files: File[]) => void;
  disabled?: boolean;
  invalid?: boolean;
  uploadedFileInfo?: { fileName: string; icon: React.ReactNode };
  isImageFile?: boolean;
  imageBase64Data?: string;
}

const useStyles = makeStyles(theme => ({
  invalid: { border: `1px solid ${theme.palette.error.main}` },
  imageDropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 2,
    padding: 15,
    color: theme.palette.text.hint,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    '&:focus': {
      borderColor: theme.palette.primary[theme.palette.type]
    }
  },
  active: { borderColor: theme.palette.primary[theme.palette.type] },
  accept: { borderColor: '#00e676' },
  reject: { borderColor: theme.palette.error[theme.palette.type] },
  fileRow: {
    '& .hoverIcon': {
      display: 'none'
    },
    '&:hover .icon': {
      display: 'none'
    },
    '&:hover .hoverIcon': {
      display: 'inline-block'
    }
  }
}));

function PaperWrapper({ children, noPaper, invalid, styles }: any) {
  return noPaper ? <>{children}</> : <Paper className={invalid ? styles.invalid : undefined}>{children}</Paper>;
}

const DropzoneContainer = ({
  icon,
  onDrop,
  disabled,
  infoText,
  isDragAcceptIcon,
  isDragRejectIcon,
  rejectText,
  acceptText,
  loadingIcon,
  loadingText,
  invalid,
  uploadedFileInfo,
  isImageFile,
  imageBase64Data
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, _, event) => {
      event.preventDefault();
      setLoading(true);
      Promise.resolve(onDrop(acceptedFiles)).then(() => setLoading(false));
    },
    accept: isImageFile
      ? 'image/jpeg,image/png'
      : 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
    disabled
  });

  const generateDropzoneMessage = (icon: React.ReactNode, text: string) => {
    return (
      <div>
        {icon}
        <Typography color={disabled ? 'textSecondary' : 'primary'}>{text}</Typography>
      </div>
    );
  };

  return (
    <PaperWrapper noPaper={isImageFile} invalid={invalid} styles={styles}>
      <div
        {...getRootProps(
          isImageFile
            ? {
                className: `${styles.imageDropzone} ${isDragActive ? styles.active : ''} ${
                  isDragAccept ? styles.accept : ''
                } ${isDragReject ? styles.reject : ''}`
              }
            : {}
        )}
      >
        <input {...getInputProps()} />
        <Box p={5} style={{ pointerEvents: 'none' }}>
          <div style={{ textAlign: 'center' }}>
            {loading && generateDropzoneMessage(loadingIcon, loadingText)}
            {isDragAccept && !loading && !imageBase64Data && generateDropzoneMessage(isDragAcceptIcon, acceptText)}
            {isDragReject && !loading && !imageBase64Data && generateDropzoneMessage(isDragRejectIcon, rejectText)}
            {!isDragActive &&
              !loading &&
              !imageBase64Data &&
              generateDropzoneMessage(
                uploadedFileInfo ? uploadedFileInfo.icon : icon,
                uploadedFileInfo ? uploadedFileInfo.fileName : infoText
              )}
            {isImageFile && imageBase64Data && (
              <img src={imageBase64Data} style={{ maxWidth: '100px' }} alt="Resume Image" />
            )}
          </div>
        </Box>
      </div>
    </PaperWrapper>
  );
};

export default DropzoneContainer;
