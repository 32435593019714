import { performGet, performPut } from 'core/apiUtils';

export interface ProfilePersonalData {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  title?: string;
  address?: {
    country?: string;
    city?: string;
    postalCode?: string;
    street?: string;
    streetNumber?: string;
  };
  phone?: string;
  mobile?: string;
  projectNumber?: string;
  currentJobPosition?: string;
  website?: string;
  xingUrl?: string;
  linkedinUrl?: string;
}

export async function getDetails() {
  return performGet<ProfilePersonalData>('/details');
}

export async function updateDetails(details: ProfilePersonalData) {
  return performPut<void>('/details', details);
}
