import React from 'react';

import { miscellaneousDataFieldList } from './inputFieldList';
import MultipleEntryList from './MultipleEntryList';
import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';

export interface MiscellaneousType {
  miscDescription?: string;
}

export const InitialMiscellaneousState = {
  miscDescription: ''
};

export default function MiscellaneousStep() {
  const { miscellaneousInfo, miscellaneousList } = useResumeGeneratorState();
  const { updateMiscellaneousInfo, updateMiscellaneousList } = useResumeGeneratorDispatchers();

  function newEntryStateUpdate(property: string, value: string) {
    updateMiscellaneousInfo({ property, value });
  }

  function addNewEntry(entry: MiscellaneousType) {
    updateMiscellaneousList({ entry });
    updateMiscellaneousInfo({
      property: '',
      value: '',
      resetValue: true,
      initialState: InitialMiscellaneousState
    });
  }

  function removeEntryFromList(index: number) {
    updateMiscellaneousList({ removeEntryAtIndex: index });
  }

  function removeAllEntries() {
    updateMiscellaneousList({ clearList: true });
  }

  return (
    <MultipleEntryList
      title="Sonstiges"
      dataFieldList={miscellaneousDataFieldList}
      listOfEntries={miscellaneousList}
      newEntryState={miscellaneousInfo}
      newEntryStateUpdate={newEntryStateUpdate}
      addNewEntry={addNewEntry}
      removeEntryFromList={removeEntryFromList}
      removeAllEntries={removeAllEntries}
      getColSize={_ => 12}
    />
  );
}
