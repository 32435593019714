import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import useErrorHandler from 'core/utils/useErrorHandler';
import React, { useState } from 'react';
import { deleteAccount } from '../api/account';

interface Props {
  open: boolean;
  onClose: () => void;
  brand: string;
}

const DeleteAccountDialog = ({ open, onClose, brand }: Props) => {
  const handleError = useErrorHandler();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleDelete() {
    setLoading(true);
    deleteAccount()
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        handleError(err);
      });
  }

  function getRedirectURL(brand: string | undefined) {
    switch (brand) {
      case 'matchflix':
        return 'https://www.match-flix.de';

      case 'interim':
        return 'https://www.senator-partners.de/';

      default:
        return 'https://www.senator-partners.de/';
    }
  }

  return (
    <Dialog open={open} onClose={success || loading ? undefined : onClose}>
      <DialogTitle id="alert-dialog-title">Account löschen</DialogTitle>
      <DialogContent>
        {success ? (
          <DialogContentText id="alert-dialog-description">
            <p>Ihr Account wurde erfolgreich gelöscht.</p>
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description">
            <p>Sind Sie sicher, dass Sie Ihren Account löschen wollen?</p>
            <p>
              Sie werden keine weiteren Job-Angebote und Anfragen erhalten. Ihre persönlichen Daten werden aus unserer
              Datenbank entfernt.
            </p>
            <p>Wenn Sie wieder Angebote erhalten wollen, müssen Sie sich erneut registrieren.</p>
          </DialogContentText>
        )}
      </DialogContent>
      {success ? (
        <DialogActions>
          <Button href={getRedirectURL(brand)} variant="contained" color="primary" autoFocus>
            Fenster schließen
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="secondary" disabled={loading}>
            Abbrechen
          </Button>
          <Button onClick={handleDelete} variant="contained" color="primary" autoFocus disabled={loading}>
            {loading && <CircularProgress size={20} style={{ marginRight: 10 }} />}
            Ja, Account löschen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DeleteAccountDialog;
