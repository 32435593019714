import React from 'react';

import { educationDataFieldList } from './inputFieldList';
import MultipleEntryList from './MultipleEntryList';
import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';

export interface EducationType {
  education?: string;
  institute?: string;
  educationType?: string;
  educationFromDate?: Date;
  educationUntilDate?: Date;
  educationDescription?: string;
}

export const InitialEducationEntryState = {
  education: '',
  institute: '',
  educationType: '',
  educationFromDate: undefined,
  educationUntilDate: undefined,
  educationDescription: ''
};

export default function EducationStep() {
  const state = useResumeGeneratorState();
  const dispatchers = useResumeGeneratorDispatchers();

  function newEntryStateUpdate(property: string, value: string | Date) {
    dispatchers.updateEducationInfo({ property, value, resetValue: false });
  }

  function addNewEntry(entry: EducationType) {
    dispatchers.updateEducationList({ entry });
    dispatchers.updateEducationInfo({
      property: '',
      value: '',
      resetValue: true,
      initialState: InitialEducationEntryState
    });
  }

  function removeEntryFromList(index: number) {
    dispatchers.updateEducationList({ removeEntryAtIndex: index });
  }

  function removeAllEntries() {
    dispatchers.updateEducationList({ clearList: true });
  }

  const getColSize = (type: string) => {
    switch (type) {
      case 'description':
        return 12;
      case 'date':
        return 3;
      default:
        return 6;
    }
  };

  return (
    <MultipleEntryList
      title="Schule / Ausbildung / Praktika"
      dataFieldList={educationDataFieldList}
      listOfEntries={state.educationList}
      newEntryState={state.educationInfo}
      newEntryStateUpdate={newEntryStateUpdate}
      addNewEntry={addNewEntry}
      removeEntryFromList={removeEntryFromList}
      removeAllEntries={removeAllEntries}
      getColSize={getColSize}
    />
  );
}
