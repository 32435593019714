import { Button, Grid, Typography } from '@material-ui/core';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  websiteLink: string;
  children?: React.ReactNode;
}

const Summary = ({ websiteLink, children }: Props) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
      <Grid item>
        <DoneOutlinedIcon fontSize="large" color="primary" />
      </Grid>
      <Grid item>
        {children || (
          <Typography align="center" color="primary" variant="h5">
            Vielen Dank
          </Typography>
        )}
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid container item md={6} xs={12} direction="column" alignItems="center">
          <Button component={Link} to="/" variant="contained" color="primary">
            Mein Profil öffnen
          </Button>
        </Grid>
        <Grid container item md={6} xs={12} direction="column" alignItems="center">
          <Button href={websiteLink} variant="contained" color="primary">
            Zurück zur Webseite
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
