import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { listDocuments, UploadedFile } from 'shared/api/document';
import { submitProfile } from 'shared/api/progress';
import DocumentsUpload from 'shared/components/documents/DocumentsUpload';
import { RegistrationStep } from 'shared/components/RegistrationStepper';

const documentsStep: RegistrationStep<UploadedFile[], false> = {
  title: 'Dokumente',
  component: props => {
    const { t } = useTranslation(process.env.REACT_APP_MODULE);
    return (
      <DocumentsUpload {...props} enableResumeDropZone>
        <Box pb={4}>
          <Typography paragraph>
            <Typography variant="subtitle1" color="textSecondary"/>
            {t('documentstep.subtitle.upload-documents-information')}
            <Typography variant="subtitle1" color="textSecondary">
              {t('documentstep.subtitle.data-privacy-information')}
            </Typography>
          </Typography>
          {t('documentstep.subtitle.missing-cv-information')}
          <Typography paragraph variant="subtitle1" color="textSecondary"/>
        </Box>
      </DocumentsUpload>
    );
  },
  initialState: [],
  onSave: () => submitProfile(),
  onLoad: listDocuments
};

export default documentsStep;
