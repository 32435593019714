import { TextField } from '@material-ui/core';
import React from 'react';

import DatePicker from 'core/components/generic_components/DatePicker';

interface Props {
  property: string;
  label: string;
  value: string | undefined | Date;
  type: 'date' | 'textField' | 'description' | 'dropDown';
  onChange: (property: string, value: string | Date) => void;
  readOnly?: boolean;
  dropDownOptions?: string[];
  helperText?: string;
  required?: boolean;
}

export default function ResumeInputField({
  property,
  label,
  value,
  type,
  onChange,
  readOnly,
  dropDownOptions,
  helperText,
  required
}: Props) {
  function getFieldByType() {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            label={label}
            value={typeof value === 'string' ? undefined : value}
            onChange={newDate => {
              if (!readOnly) {
                onChange(property, newDate);
              }
            }}
            readOnly={readOnly}
            helperText={helperText}
            required={required}
          />
        );
      case 'description':
        return (
          <TextField
            color="primary"
            fullWidth
            label={label}
            value={value}
            InputProps={{
              readOnly,
              disableUnderline: readOnly
            }}
            onChange={(event: any) => {
              if (!readOnly) {
                onChange(property, event.target.value);
              }
            }}
          />
        );
      case 'dropDown':
        return (
          <TextField
            select
            color="primary"
            fullWidth
            label={label}
            value={value}
            onChange={(event: any) => {
              if (!readOnly) {
                onChange(property, event.target.value);
              }
            }}
            SelectProps={{
              native: true
            }}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly,
              disableUnderline: readOnly
            }}
          >
            <option key="default_drop_value" value="">
              Bitte wählen
            </option>
            {dropDownOptions &&
              dropDownOptions.map(value => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
          </TextField>
        );
      default:
        const hasError = required && !!!value;
        let helperTextProp: any = '';
        if (hasError) {
          helperTextProp = 'Pflichtfeld';
        } else {
          if (!readOnly) {
            helperTextProp = helperText;
          }
        }
        return (
          <TextField
            color="primary"
            fullWidth
            label={label}
            value={value}
            InputProps={{
              readOnly,
              disableUnderline: readOnly,
              notched: readOnly
            }}
            onChange={(event: any) => {
              if (!readOnly) {
                onChange(property, event.target.value);
              }
            }}
            helperText={helperTextProp}
            required={required}
            error={hasError}
          />
        );
    }
  }

  return <>{getFieldByType()}</>;
}
