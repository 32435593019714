import React from 'react';
import { StepComponentProps } from '../../RegistrationStepper';
import { ClientPersonalData, PersonalDataFormFields } from './PersonalDataFormFields';

interface Props extends StepComponentProps<ClientPersonalData> {
  enableJobPosition?: boolean;
  children?: React.ReactNode;
}

const PersonalDataForm = ({ state, setState, readOnly, isProfileSection, enableJobPosition, children }: Props) => {
  return (
    <React.Fragment>
      {!isProfileSection && children}
      <PersonalDataFormFields
        readOnly={readOnly}
        onChange={setState}
        value={state}
        enableJobPosition={enableJobPosition}
      />
    </React.Fragment>
  );
};

export default PersonalDataForm;
