import { Box, Button, Grid, MobileStepper } from '@material-ui/core';
import React from 'react';
import { StepAttributes } from './StepperWithControls';

interface Props {
  steps: StepAttributes[];
  activeStep: number;
  disabled?: boolean;
}

const StepperControls = ({ steps, activeStep, disabled }: Props) => {
  const nextButton = {
    label: 'Weiter',
    disabled,
    visible: activeStep + 1 < steps.length
  };
  const backButton = {
    label: 'Zurück',
    disabled: activeStep === 0 || disabled,
    visible: !steps[activeStep].noBackButton
  };

  return (
    <div>
      <Box component="div" display={{ xl: 'none', lg: 'none', md: 'none', sm: 'none' }}>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            backButton.visible ? (
              <Button type="submit" color="primary" variant="contained" size="small" disabled={nextButton.disabled}>
                {nextButton.label}
              </Button>
            ) : (
              <span />
            )
          }
          backButton={
            backButton.visible ? (
              <Button type="reset" color="primary" variant="contained" size="small" disabled={backButton.disabled}>
                {backButton.label}
              </Button>
            ) : (
              <span />
            )
          }
        />
      </Box>
      <Box display={{ xs: 'none', xl: 'block', lg: 'block', md: 'block', sm: 'block' }} px={3}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
          {backButton.visible && (
            <Grid item>
              <Button type="reset" variant="contained" color="primary" disabled={backButton.disabled}>
                {backButton.label}
              </Button>
            </Grid>
          )}
          {nextButton.visible && (
            <Grid item>
              <Button type="submit" variant="contained" color="primary" disabled={nextButton.disabled}>
                {nextButton.label}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default StepperControls;
