import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  dialogContentText: React.ReactFragment;
  dialogTitle: string;
  closeButtonName?: string;
  submitButtonName?: string;
  disabled?: boolean;
  width: any;
}

const ConfirmationDialogWrapper = ({
  open,
  handleClose,
  handleSubmit,
  dialogContentText,
  dialogTitle,
  closeButtonName,
  submitButtonName,
  disabled,
  width
}: Props) => {
  const isExtraSmall = isWidthDown('xs', width);
  const closeButton = (
    <Button onClick={handleClose} variant="contained" color="primary">
      {closeButtonName || 'Nein'}
    </Button>
  );
  const submitButton = (
    <Button onClick={handleSubmit} variant="contained" color="primary" autoFocus>
      {submitButtonName || 'Ja'}
    </Button>
  );

  function renderButtonsByWidth() {
    return isExtraSmall ? (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box p={2}>{submitButton}</Box>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box p={2}>{closeButton}</Box>
        </Grid>
      </Grid>
    ) : (
      <>
        {closeButton}
        {submitButton}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isExtraSmall}
    >
      <DialogTitle id="alert-dialog-title" style={isExtraSmall ? { textAlign: 'center' } : {}}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={isExtraSmall ? { textAlign: 'center' } : {}}>
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{renderButtonsByWidth()}</DialogActions>
    </Dialog>
  );
};

export default withWidth()(ConfirmationDialogWrapper);
