import { BASE_URL } from 'core/apiUtils';
import Keycloak from 'keycloak-js';

export let keycloak: Keycloak.KeycloakInstance;

export async function initialize(clientId: string) {
  if (keycloak) {
    return Promise.resolve();
  }
  return fetch(`${BASE_URL}/keycloak.json`)
    .then(res => {
      if (!res.ok) {
        throw new Error(`${res.status}`);
      }
      return res.json();
    })
    .then(config => {
      keycloak = Keycloak({
        ...config,
        clientId
      });
    });
}

export function initializeMock() {
  keycloak = Keycloak();
}
