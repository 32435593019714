import { CircularProgress, Grid } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  CloudUpload as CloudUploadIcon,
  NotInterested as NotInterestedIcon
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import DropzoneContainer from 'core/components/generic_components/DropzoneContainer';
import { getBasePersonalData } from 'shared/api/generateResume';
import { personalDataFieldList } from './inputFieldList';
import { useResumeGeneratorDispatchers, useResumeGeneratorState } from './ResumeGeneratorContextManager';
import ResumeInputField from './ResumeInputField';
import ResumeStepperContent from './ResumeStepperContent';

export interface PersonalDataStateType {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  city?: string;
  postalCode?: string;
  street?: string;
  maritalStatus?: string;
  dateOfBirth?: Date;
}

export const InitialPersonalDataState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobile: '',
  city: '',
  postalCode: '',
  street: '',
  maritalStatus: '',
  dateOfBirth: undefined
};

export default function BasePersonalDataStep() {
  const [loading, setLoading] = useState(false);
  const { personalData, isEdit, resumeImage } = useResumeGeneratorState();
  const dispatchers = useResumeGeneratorDispatchers();

  async function getProfileBaseData() {
    setLoading(true);
    const apiResponse = await getBasePersonalData();
    Object.keys(apiResponse).forEach(property => {
      dispatchers.updatePersonalData({ property, value: apiResponse[property] });
    });
    setLoading(false);
  }

  useEffect(() => {
    if (!isEdit) {
      getProfileBaseData();
    }
  }, [isEdit]);

  function requiredFieldsAreNotEmpty(data: PersonalDataStateType) {
    const checkIfDateNotEmpty = (date: Date | null | undefined) => {
      if (date) {
        return isNaN(date.getTime());
      } else {
        return true;
      }
    };
    return Object.keys(data)
      .filter(entry => entry !== 'maritalStatus' && entry !== 'phone')
      .map(key => data[key])
      .map(entry => (typeof entry === 'string' ? entry !== '' : !checkIfDateNotEmpty(entry)))
      .reduce((firstValue, secondValue) => firstValue && secondValue);
  }

  function onChange(property: string, value: string) {
    dispatchers.updatePersonalData({ property, value });
  }

  function generatePersonalDataFields() {
    return personalDataFieldList.map((entry: any) => (
      <Grid key={`key_id_tif_${entry.fieldId}_grid_item`} item md={6}>
        <ResumeInputField
          property={entry.fieldId}
          label={entry.label}
          value={personalData[entry.fieldId]}
          type={entry.fieldType}
          onChange={onChange}
          dropDownOptions={entry.listOfSelections ? entry.listOfSelections : []}
          required={entry.required}
        />
      </Grid>
    ));
  }

  async function toBase64(image: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async function onImageDrop(images: File[]) {
    const imageInBase64: any = await toBase64(images[0]);
    dispatchers.setResumeImage(imageInBase64);
  }

  const loadingIndicator = (
    <Grid item md={12} style={{ textAlign: 'center' }}>
      <CircularProgress size={120} />
    </Grid>
  );

  return (
    <ResumeStepperContent title="Stammdaten" nextStepDisabled={!requiredFieldsAreNotEmpty(personalData)}>
      <Grid item sm={12}>
        <DropzoneContainer
          icon={<CloudUploadIcon fontSize="large" color="primary" />}
          isDragRejectIcon={<NotInterestedIcon fontSize="large" color="primary" />}
          isDragAcceptIcon={<CheckCircleIcon fontSize="large" color="primary" />}
          loadingIcon={<CircularProgress />}
          infoText="Bild hochladen"
          rejectText="Dieser format wird nicht unterstützt"
          acceptText="Bild hochladen"
          loadingText="Upload läuft..."
          invalid={false}
          disabled={false}
          onDrop={onImageDrop}
          isImageFile
          imageBase64Data={resumeImage}
        />
      </Grid>
      {loading ? loadingIndicator : generatePersonalDataFields()}
    </ResumeStepperContent>
  );
}
